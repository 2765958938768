import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DlocalCard, { DlocalCardData } from '@/features/credit-card/dlocal-card';
import FormFieldView from '@/features/form-field-view';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { CreditCardForm } from '@/lib/forms/actions';
import { Countries } from '@/lib/locale/countries';
import { FormSection, FormValues } from '@/lib/payments/forms';
import DlocalService from '@/services/dlocal-service';

import {
    DlocalTokenizedCardData
} from '../../../../../../../graphql/bank-card/dlocal/create-card-dlocal';
import { AppData } from '../../../../../index';

type DLocalBankCardViewProps = {
    dlocalService: DlocalService;
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    formFields: FormSection[];
    onError: (error: string) => void;
    onSubmit: (formFillValues: FormValues<DlocalTokenizedCardData>) => Promise<GQLErrorType>;
}

const DLocalBankCardView: FC<DLocalBankCardViewProps> = ({
    dlocalService,
    country,
    savedDocuments,
    formFields,
    onError,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const creditCardFormRef = useRef<CreditCardForm<DlocalCardData>>(null);

    const handleSubmit = async (paymentFillFormData: FormValues) => {
        if (!creditCardFormRef.current) {
            return;
        }

        if (!creditCardFormRef.current.validate()) {
            return;
        }

        const creditCardData = await creditCardFormRef.current.getData();
        if (creditCardData === null) {
            return;
        }

        const error = await onSubmit({
            token: creditCardData.token,
            cardHolderName: creditCardData.cardHolderName,
            ...paymentFillFormData
        });

        if (error?.isValidationError()) {
            const fieldName = 'cardHolderName';
            const cardHolderError = error.getErrorBy(fieldName);

            if (cardHolderError) {
                creditCardFormRef.current.setCardholderError({
                    message: cardHolderError
                });
            }
        }

        return error;
    };

    return (
        <FormFieldView
            country={country}
            savedDocuments={savedDocuments}
            formFields={formFields}
            onSubmit={handleSubmit}
            childrenTitle={t('views.PaymentMethods.BankCard.creditCardTitle')}
        >
            <DlocalCard
                dlocalService={dlocalService}
                ref={creditCardFormRef}
                onError={onError}
            />
        </FormFieldView>
    );
};

export default DLocalBankCardView;

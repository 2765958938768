import {
    BottomSheet,
    BottomSheetBody,
    BottomSheetCloseIcon,
    BottomSheetHeader,
    BottomSheetHeaderPostfix
} from '@indriver/nova';
import { FC, ReactElement, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import * as UI from './ui';

import Resources from '../../../../resources';

export type ModalProps = {
    title?: string;
    children: ReactElement;
    /** Root id is container for creating modal outside of layout */
    rootElementId: string;
    open: boolean;
    onClose: VoidFunction;
}

// if you set rtl by ar locale, modal will be 0x0
const MODAL_COMPONENT_DIRECTION_BUGFIX = 'ltr';
// use dir={getDir()} inside of layout

const Modal: FC<ModalProps> = ({
    title,
    rootElementId,
    open,
    onClose,
    children
}) => {
    const { t } = useTranslation();

    const [container, setContainer] = useState<HTMLElement | null>(null);

    useEffect(() => {
        const node = document.getElementById(rootElementId);
        if (node) {
            setContainer(node);

            return;
        }

        const mainElement = document.querySelector<HTMLBodyElement>('main');

        if (mainElement && open) {
            const portalContainer = document.createElement('div');
            portalContainer.id = rootElementId;
            mainElement.appendChild(portalContainer);
            setContainer(portalContainer);
        }
    }, [open]);

    if (!container) {
        return null;
    }

    return createPortal(
        <div dir={MODAL_COMPONENT_DIRECTION_BUGFIX}>
            <UI.Modal id={rootElementId} />

            <BottomSheet
                opened={open}
                rootSelector={'#' + rootElementId}
            >
                <UI.BottomHeaderBold>
                    <BottomSheetHeader
                        title={title}
                        data-id={Resources.test.bottomSheetHeading}
                    />
                </UI.BottomHeaderBold>

                <BottomSheetHeaderPostfix
                    aria-label={t('features.PaymentMethodsList.closeLabel')}
                    data-id={Resources.test.bottomSheetClose}
                    onClick={onClose}
                >
                    <BottomSheetCloseIcon />
                </BottomSheetHeaderPostfix>

                <BottomSheetBody>
                    {children}
                </BottomSheetBody>
            </BottomSheet>
        </div>,
        container
    );
};

export default Modal;

import EventEmitter from '@/lib/subscriber';
import ConfigurationService from '@/services/configuration-service';

declare global {
    interface Window {
        // https://docs.nuvei.com/documentation/accept-payment/web-sdk/using-react-code/
        SafeCharge: {
            new(options: {
                env: 'prod' | 'int';
                merchantId: string;
                merchantSiteId: string;
                sessionToken: string;
            }): SafeChargeInstance;
        };
    }
}

export enum NuveiFieldType {
    ccNumber = 'ccNumber',
    ccExpiration = 'ccExpiration',
    ccCvc = 'ccCvc'
}

type GetTokenResponse = {
    status: 'ERROR' | 'SUCCESS';
    errCode: 1019;
    reason: string;
    error: {
        type: string
        code: number;
        message: string;
    };
    ccTempToken: string;
    isVerified: boolean;
    uniqueCC: string;
    cardType: string;
    issuerCountry: string;
    internalRequestId: number;
    version: string;
    sessionToken: string;
}

type SafeChargeInstance = {
    fields: (options: { locale?: string }) => SafeChargeFieldsInstance;
    getToken: (card: SafeChargeField, options: {
        sessionToken: string
        cardHolderName: string
        billingAddress: {
            email: string
            country: string
        }
    }) => Promise<GetTokenResponse>;
}

type SafeChargeFieldsInstance = {
    create: (fieldType: NuveiFieldType, options: any) => SafeChargeField;
}

type FieldEvent = 'blur' | 'change' | 'focus' | 'ready';

type FieldEventCallback = (event: CardField | ExpirationField | CvcField) => void;

export type SafeChargeField = {
    attach: (selector: string) => void;
    on: (event: FieldEvent, callback: FieldEventCallback) => void;
}

type FieldError = {
    id: string;
    message: string;
    field: string;
    paste: boolean;
    scanned: boolean;
};

type BaseField = {
    cardType: string;
    empty: boolean;
    complete: boolean;
    paste: boolean;
    allFieldsCompleted: boolean;
    scanned: boolean;
    error?: FieldError;
};

type CardField = BaseField & {
    field: NuveiFieldType.ccNumber;
};

type ExpirationField = BaseField & {
    field: NuveiFieldType.ccExpiration;
};

type CvcField = BaseField & {
    field: NuveiFieldType.ccCvc;
    optional: boolean;
};

type NuveiConfig = {
    paymentID: string;
    sessionToken: string;
    country: string;
};

class NuveiService extends EventEmitter<{
    'onReady': true;
}> {
    private instance: SafeChargeInstance;
    private readonly config: NuveiConfig;
    private configurationService: ConfigurationService;

    public fields: Partial<Record<NuveiFieldType, SafeChargeField>> = {};
    private readyFields: NuveiFieldType[] = [];

    constructor(config: {
        configurationService: ConfigurationService;
        safeCharge: SafeChargeInstance;
        sessionToken: string;
        country: string;
        paymentID: string;
    }) {
        super();
        this.instance = config.safeCharge;
        this.configurationService = config.configurationService;
        this.config = {
            paymentID: config.paymentID,
            sessionToken: config.sessionToken,
            country: config.country
        };
    }

    private setFields = (name: NuveiFieldType, field: SafeChargeField) => {
        this.fields[name] = field;
    };

    public getConfig = () => {
        return this.config;
    };

    public getIsReady = () => {
        return this.readyFields.length === Object.keys(this.fields).length;
    };

    public mountFields = (fieldIds: NuveiFieldType[]) => {
        const isDarkTheme = this.configurationService.getIsDarkTheme();
        const fieldsInstance = this.instance
            .fields({
                locale: 'en',
            });
        fieldIds.forEach((id) => {
            const field = fieldsInstance
                .create(id, {
                    style: {
                        base: {
                            color: isDarkTheme ? '#ffffff' : '#323942',
                            fontSize: '16px' },
                    }
                });

            field.attach(`#${id}`);
            field.on('ready', () => {
                this.readyFields.push(id);
                const isAllReady = this.getIsReady();
                if(isAllReady){
                    this.dispatch('onReady', true);
                }
            });

            this.setFields(id, field);
        });
    };

    public getCcNumberToken = async (option: { email: string, cardHolderName: string }) => {
        const field = this.fields[NuveiFieldType.ccNumber];
        if (!field) {
            return;
        }

        return await this.instance.getToken(field, {
            sessionToken: this.config.sessionToken,
            cardHolderName: option.cardHolderName,
            billingAddress: {
                email: option.email,
                country: this.config.country
            }
        });
    };

    public on = (event: FieldEvent, callback: FieldEventCallback) => {
        Object.entries(this.fields).forEach(([, field]) => {
            field?.on(event, callback);
        });
    };
}

export default NuveiService;
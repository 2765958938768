import { Progress } from '@indriver/mireska';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InformationScreen } from '@/features/information-screen';
import IPCService from '@/services/ipc-service';
import NavigatorService from '@/services/navigator-service';

type ReturnPageProps = {
    ipcService: IPCService;
    navigatorService: NavigatorService;
}

const ReturnPage: FC<ReturnPageProps> = ({ ipcService, navigatorService }) => {
    const { t } = useTranslation();
    const [outOfApp, setOutOfApp] = useState(false);
    const [userStillOnThePage, setUserStillOnThePage] = useState(false);

    useEffect(() => {
        window.access('returnpage');
        ipcService.sendPaymentInProgress();

        ipcService.refreshJWT()
            .catch(() => setOutOfApp(true));
    }, []);

    const handleClickButton = useCallback(() => {
        navigatorService.goWallet();
        setUserStillOnThePage(true);
    }, [outOfApp]);

    return (
        <InformationScreen
            type='in-progress'
            heading={t('pages.successPage.title')}
            icon={Progress}
            description={
                userStillOnThePage ?
                    t('pages.returnPage.description') :
                    t('pages.successPage.description')
            }
            buttonText={
                outOfApp ?
                    t('pages.returnPage.submitCaption') :
                    t('pages.successPage.submitCaption')
            }
            onClickButton={userStillOnThePage ? undefined : handleClickButton}
        />
    );
};

export default ReturnPage;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormFieldView from '@/features/form-field-view';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { FormValues } from '@/lib/payments/forms';
import { PaymentType } from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import { DataType } from '@/services/payment-provider-service';

import { PaymentFormData } from '../index';

const FawryCashController: FC<PaymentFormData> = ({
    paymentInfo,
    services,
    store,
}) => {
    const { t } = useTranslation();

    const handlePayment = async <T extends DataType['TopupWithFawryCashInput']>(input: T) => {
        services.analyticService.event('processing.topup_pay.click', {
            payment_option: PaymentType.CASH,
            provider: PaymentProviderName.Fawry,
            sum: parseInt(store.amount, 10),
        });

        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.topupWithFawryCash(input);

            if (data?.topupWithFawryCash) {
                services.paymentInfoService.setFawryCashData(data.topupWithFawryCash);
            }
        });
    };

    const handleSubmitPayment = async (formFillValues: FormValues): Promise<GQLErrorType> => {
        return handlePayment({
            email: formFillValues.email,
            amount: parseInt(store.amount, 10),
        });
    };

    return (
        <FormFieldView
            title={t('views.PaymentMethods.getVoucherSubmitCaption')}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={store.selectedMethod.formFields}
            onSubmit={handleSubmitPayment}
        />
    );
};

export default FawryCashController;

import JWTToken from '@/lib/jwt';
import IPCService from '@/services/ipc-service';
import LogsService from '@/services/logs-service';

import Resources from '../../resources';

// https://indriver.atlassian.net/wiki/spaces/UXD/pages/1343422465/balance+New+Order
export type AnalyticEventMap = {
    'processing.topup_saved_card_delete.click': undefined;
    'processing.topup_pay.click': {
        payment_option: string,
        provider: string,
        sum: number;
    };
    'processing.topup_pay.amount_click': {
        amount: string;
    };
    'processing.topup_saved_card_pay.click': undefined;
    'processing.topup_payment_option.click': {
        payment_option: string,
        provider: string,
    };
    'processing.topup_pay_auto.click': undefined,
    'processing.topup_auto.view': undefined,
    'processing.topup_auto_enable.click': {
        enable: boolean,
        amount: string,
        balance_threshold: string,
        payment_option: string,
        provider: string,
    },
    'processing.topup_auto_save.click': {
        enable: boolean,
        amount: string,
        balance_threshold: string,
        payment_option: string,
        provider: string,
    }
    'processing.topup_auto_disable.click': {
        enable: boolean,
        amount: string,
        balance_threshold: string,
        payment_option: string,
        provider: string,
    }
}

class AnalyticsService {
    private logsService: LogsService;
    private ipcService: IPCService;
    private jwt?: JWTToken;

    constructor(
        logsService: LogsService,
        ipcService: IPCService,
    ) {
        this.logsService = logsService;
        this.ipcService = ipcService;
    }

    public async init(jwt: JWTToken): Promise<void> {
        try {
            const { SwrveSDK } = await import('@swrve/web-sdk');
            SwrveSDK.initWithConfig({
                appId: 6915,
                apiKey: Resources.SWERVE_SDK_API_KEY,
                stack: 'eu',
                externalUserId: String(jwt.getToken().user_id),
            });

            this.jwt = jwt;
        } catch (_) {
            this.logsService.write('error_swerve');
        }
    }

    /** @deprecated */
    private async swerveEvent(eventName: string, payload: Record<string | number, any>) {
        const { SwrveSDK } = await import('@swrve/web-sdk');
        let instance;

        try {
            instance = SwrveSDK.getInstance();
        } catch (_) {/** do nothing */}

        if (instance) {
            try {
                SwrveSDK.event(eventName, payload);
                SwrveSDK.sendQueuedEvents(); // send batch immediately
            } catch (_) {/** do nothing */}
        }
    }

    public event<K extends keyof AnalyticEventMap> (eventName: K, payload: AnalyticEventMap[K]): void {
        this.logsService.write(`event_${eventName}_${JSON.stringify(payload)}`);
        this.ipcService.sendAnalyticsMessage({
            name: eventName,
            payload,
        });

        if (!this.jwt) {
            return;
        }

        const { city_id, country_id, user_id } = this.jwt.getToken();

        this.swerveEvent(eventName, {
            country_id,
            city_id,
            user_id,
            ...payload,
        });
    }
}

export default AnalyticsService;

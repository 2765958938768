import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DlocalCard, {
    DlocalCardData
} from '@/features/credit-card/dlocal-card';
import FillForm from '@/features/payment-fill-form';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { CreditCardForm } from '@/lib/forms/actions';
import { Countries } from '@/lib/locale/countries';
import { formatLast4toDots } from '@/lib/payments/credit-card/format';
import {
    FormSection,
    FormValues,
} from '@/lib/payments/forms';
import DlocalService from '@/services/dlocal-service';
import FormInput from '@/shared/ui/core/form-input';
import SubmitForm from '@/shared/ui/domain/submit-form';

import Resources from '../../../../../../../resources';
import { AppData } from '../../../../../index';

type DlocalSavedCardProps = {
    dlocalService: DlocalService;
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    panValue: string;
    cvvOnly: boolean;
    formFields: FormSection[];
    onTopUpClick: VoidFunction;
    onSubmit: (formFillValues: FormValues) => Promise<GQLErrorType>;
    onError: (error: string) => void;
}

const DlocalSavedCardView: FC<DlocalSavedCardProps> = ({
    dlocalService,
    country,
    savedDocuments,
    panValue,
    cvvOnly,
    formFields,
    onTopUpClick,
    onSubmit,
    onError,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const paymentFillFormRef = useRef<FillForm>(null);
    const dlocalCardFormRef = useRef<CreditCardForm<DlocalCardData>>(null);

    const handleSubmit = useCallback(async () => {
        onTopUpClick();

        let data: Record<string, string> = {};

        if (paymentFillFormRef.current) {
            if (!paymentFillFormRef.current.validate()) {
                return;
            }

            const paymentFillFormData = paymentFillFormRef.current.getData();

            data = {
                ...paymentFillFormData,
            };
        }

        setLoading(true);
        if (cvvOnly && dlocalCardFormRef.current?.getCvvOnly) {
            const cvvOnlyToken = await dlocalCardFormRef.current.getCvvOnly();
            if (!cvvOnlyToken) {
                setLoading(false);

                return;
            }


            data['token'] = cvvOnlyToken;
        }

        const error = await onSubmit(data);
        if (error) {
            if (paymentFillFormRef.current) {
                paymentFillFormRef.current.setError(error);
            }

            setLoading(false);
        }
    }, [paymentFillFormRef, onTopUpClick]);

    return (
        <SubmitForm
            loading={loading}
            onSubmit={handleSubmit}
        >
            <FormInput
                disabled
                title={t('shared.ui.domain.CreditCardOutline.panCaption')}
                data-id={Resources.test.existedCard}
                value={formatLast4toDots(panValue)}
            />

            {
                cvvOnly ? (
                    <DlocalCard
                        cvvOnly
                        dlocalService={dlocalService}
                        ref={dlocalCardFormRef}
                        onError={onError}
                    />
                ) : <></>
            }

            {
                formFields.length > 0 ? (
                    <FillForm
                        ref={paymentFillFormRef}
                        country={country}
                        savedDocuments={savedDocuments}
                        fields={formFields}
                    />
                ) : <></>
            }
        </SubmitForm>
    );
};

export default DlocalSavedCardView;

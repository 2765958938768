import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import NuveiCard, { NuveiCardData } from '@/features/credit-card/nuvei-card';
import FormFieldView from '@/features/form-field-view';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { CreditCardForm } from '@/lib/forms/actions';
import { Countries } from '@/lib/locale/countries';
import { FormSection, FormValues } from '@/lib/payments/forms';
import NuveiService from '@/services/nuvei-service';


import { AuthorizeWithNuveiInput } from '../../../../../../../graphql/bank-card/nuvei/authorize-with-nuvei';
import { AppData } from '../../../../../index';

type NuveiBankCardViewProps = {
    nuveiService: NuveiService;
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    formFields: FormSection[];
    onSubmit: (formFillValues: FormValues<AuthorizeWithNuveiInput>) => Promise<GQLErrorType>;
}

const NuveiBankCardView: FC<NuveiBankCardViewProps> = ({
    nuveiService,
    country,
    savedDocuments,
    formFields,
    onSubmit
}) => {
    const { t } = useTranslation();

    const nuveiRef = useRef<CreditCardForm<NuveiCardData>>(null);

    const handleSubmit = async (paymentFillFormData: FormValues) => {
        if (!nuveiRef?.current) {
            return;
        }

        const data = await nuveiRef.current.getData({ email: paymentFillFormData.email });
        if (!data?.cardToken) {
            return;
        }

        const { paymentID, sessionToken } = nuveiService.getConfig();

        return onSubmit({
            cardHolderName: data.cardHolder,
            email: paymentFillFormData.email,
            cardToken: data.cardToken,
            paymentID,
            providerSession: sessionToken
        });
    };

    return (
        <FormFieldView
            country={country}
            savedDocuments={savedDocuments}
            formFields={formFields}
            onSubmit={handleSubmit}
            childrenTitle={t('views.PaymentMethods.BankCard.creditCardTitle')}
        >
            <NuveiCard
                nuveiService={nuveiService}
                ref={nuveiRef}
            />
        </FormFieldView>
    );
};

export default NuveiBankCardView;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormFieldView from '@/features/form-field-view';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { FormValues } from '@/lib/payments/forms';
import {
    PaymentType
} from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import { DataType } from '@/services/payment-provider-service';

import { PaymentFormData } from '../index';

const IziBankCardController: FC<PaymentFormData> = ({ store, paymentInfo, services }) => {
    const { t } = useTranslation();

    const handlePayment = async <T extends DataType['TopupWithIziCardInput']>(
        input: T
    ): Promise<GQLErrorType> => {
        services.analyticService.event('processing.topup_pay.click', {
            provider: PaymentProviderName.Izi,
            payment_option: PaymentType.BANK_CARD,
            sum: parseInt(store.amount, 10),
        });

        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.topUpWithIziCreditCard(input);

            if (data?.topupWithIziCard.redirectUrl) {
                services.navigatorService.goOutside(data.topupWithIziCard.redirectUrl);

                return;
            }

            if (data?.topupWithIziCard.redirectUrl === '') {
                await services.navigatorService.showFinalPage('error');
            }
        });
    };

    const handleSubmitPayment = async (formFillValues: FormValues): Promise<GQLErrorType> => {
        return handlePayment({
            document: formFillValues.document,
            amount: parseInt(store.amount, 10),
        });
    };

    return (
        <FormFieldView
            childrenTitle={t('views.PaymentMethods.BankCard.creditCardTitle')}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={store.selectedMethod.formFields}
            onSubmit={handleSubmitPayment}
        />
    );
};

export default IziBankCardController;

import { gql } from '@apollo/client';

export enum UserAction {
    NONE = 'NONE',
    ENTER_CARD = 'ENTER_CARD',
}

export type TopupWithNuveiResponse = {
    topupWithNuvei: {
        nextAction: UserAction;
        paymentID: string;
        providerSession: string;
    };
}

export type TopupWithNuveiMutation = {
    input: TopupWithNuveiInput;
}

export type TopupWithNuveiInput = {
    amount: number;
}

export default gql(`
    mutation topupWithNuvei ($input: TopupWithNuveiInput!){
        topupWithNuvei(
            input: $input
        ) {
            nextAction
            paymentID
            providerSession
        }
    }
`);

import { FC, useCallback, useRef, useState } from 'react';

import FillForm from '@/features/payment-fill-form';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { Countries } from '@/lib/locale/countries';
import {
    FormSection,
    FormValues
} from '@/lib/payments/forms';
import SubmitForm from '@/shared/ui/domain/submit-form';

import { AppData } from '../../../../../index';

type WooppayPhoneBalanceViewProps = {
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    formFields: FormSection[];
    onSubmit: (formFillValues: FormValues) => Promise<GQLErrorType>;
}

const WooppayPhoneBalanceView: FC<WooppayPhoneBalanceViewProps> = ({
    country,
    savedDocuments,
    formFields,
    onSubmit
}) => {
    const [loading, setLoading] = useState(false);
    const paymentFillFormRef = useRef<FillForm>(null);

    const handleSubmit = useCallback(async () => {
        if (!paymentFillFormRef.current) {
            return;
        }

        if (!paymentFillFormRef.current.validate()) {
            return;
        }

        const fields = paymentFillFormRef.current.getData();

        setLoading(true);
        const error = await onSubmit(fields);

        if (error) {
            if (paymentFillFormRef.current) {
                paymentFillFormRef.current.setError(error);
            }
        }

        setLoading(false);
    }, [onSubmit]);

    return (
        <SubmitForm
            loading={loading}
            disabled={loading}
            onSubmit={handleSubmit}
        >
            <FillForm
                country={country}
                savedDocuments={savedDocuments}
                ref={paymentFillFormRef}
                fields={formFields}
            />
        </SubmitForm>
    );
};

export default WooppayPhoneBalanceView;

import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import AuthorizationService from '@/services/authorization-service';
import ConfigurationService from '@/services/configuration-service';
import IPCService from '@/services/ipc-service';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';
import NotificationService from '@/services/notification-service';

import Singlepage from './app';
import NonAuthRoutes, { isNonAuth } from './app/non-auth-routes';

type AppRouterProps = {
    ipcService: IPCService;
    logsService: LogsService;
    configurationService: ConfigurationService;
    authorizationService: AuthorizationService;
}

const AppRouter: FC<AppRouterProps> = ({
    ipcService,
    logsService,
    configurationService,
    authorizationService,
}) => {
    const [isNonAuthRoute, setIsNonAuthRoute] = useState(isNonAuth());
    const location = useLocation();
    
    const [notificationService] = useState(new NotificationService());
    const [navigatorService] = useState(new NavigatorService(
        useNavigate(),
        logsService,
        configurationService,
        authorizationService,
        notificationService
    ));

    useEffect(() => {
        const signEl = window?.document.getElementById('body-sign');
        signEl?.remove();

        setIsNonAuthRoute(isNonAuth());
    }, [location.pathname]);

    if (isNonAuthRoute) {
        return (
            <NonAuthRoutes
                navigatorService={navigatorService}
                ipcService={ipcService}
                logsService={logsService}
                configurationService={configurationService}
            />
        );
    }

    return (
        <Singlepage
            authorizationService={authorizationService}
            navigatorService={navigatorService}
            notificationService={notificationService}
            ipcService={ipcService}
            logsService={logsService}
            configurationService={configurationService}
        />
    );
};

export default AppRouter;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Resources from '../../resources';
import { PaymentMethodViewItem } from '../../services/view-service';
import PaymentMethodSelect from '../../shared/ui/domain/payment-method-select';
import ChevronArrow from '../../shared/ui/icons/arrow';
import PaymentMethodIcon from '../../shared/ui/icons/payment-method';

import * as UI from './ui';

type PaymentMethodNavigationProps = {
    paymentMethod?: PaymentMethodViewItem;
    onSelectClick: VoidFunction;
}

const PaymentMethodNavigation: FC<PaymentMethodNavigationProps> = ({
    paymentMethod,
    onSelectClick,
}) => {
    const { t } = useTranslation();

    let icon = <PaymentMethodIcon />;
    let title = t('features.PaymentMethodNavigation.defaultTitle');
    let description = t('features.PaymentMethodNavigation.description');

    if (paymentMethod) {
        icon = paymentMethod.icon;
        title = paymentMethod.name;
        description = t('features.PaymentMethodNavigation.defaultTitle');
    }

    return (
        <UI.Wrapper data-id={Resources.test.paymentMethodSelect}>
            <PaymentMethodSelect
                title={title}
                description={description}
                icon={icon}
                highlight={!paymentMethod}
                onClick={onSelectClick}
            >
                <ChevronArrow size={20} />
            </PaymentMethodSelect>
        </UI.Wrapper>
    );
};

export default PaymentMethodNavigation;

import {
    FC,
    forwardRef,
    Ref,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { CreditCardForm } from '@/lib/forms/actions';
import { ValidationError } from '@/lib/forms/validation';
import DlocalService, { DlocalCardInfo } from '@/services/dlocal-service';
import { DlocalError } from '@/services/dlocal-service/error';
import { DlocalFieldType } from '@/services/dlocal-service/options';

import DlocalInput from '../../dlocal-input';

import CardHolder from '../../../shared/ui/domain/credit-card-field/card-holder';
import CreditCardOutline, { CreditCardViewType } from '../../../shared/ui/domain/credit-card-outline';
import CenterLoader from '../../../shared/ui/layout/center-loader';

export type DlocalCardData = DlocalCardInfo;

type DlocalCardProps = {
    dlocalService: DlocalService;
    ref: Ref<CreditCardForm<DlocalCardData>>;
    cvvOnly?: boolean;
    onError: (error: string) => void;
}

const DlocalCard: FC<DlocalCardProps> = forwardRef(({ dlocalService, cvvOnly, onError }, ref) => {
    const { t } = useTranslation();

    const [cardNumberError, setCardNumberError] = useState<ValidationError>();
    const [expirationError, setExpirationError] = useState<ValidationError>();

    const [cvvError, setCvvError] = useState<ValidationError>();

    const [cardHolder, setCardHolder] = useState('');
    const [cardHolderError, setCardHolderError] = useState<ValidationError>();
    const cardholderRef = useRef<CardHolder>(null);

    const [show, setShow] = useState(false);

    useEffect(() => {
        dlocalService.addEventListener('ready', () => {
            setShow(true);
        });
    }, []);

    useImperativeHandle(ref, () => ({
        getCvvOnly: async () => {
            try {
                return await dlocalService.getCvvTokenOnly();
            } catch (e) {
                if (e instanceof DlocalError) {
                    if (e.isCvvError()) {
                        setCvvError({
                            message: e.getMessage(),
                        });
                    }
                }
            }

            return null;
        },
        validate: (): boolean => {
            const cardholderErr = cardholderRef.current?.validate();

            return Boolean(cardholderErr);
        },
        setCardholderError: (error) => {
            if (error?.message) {
                setCardHolderError(error);
            }
        },
        getData: async () => {
            try {
                return await dlocalService.getCardInfo(cardHolder.trim());
            } catch (e) {
                if (e instanceof DlocalError) {
                    if (e.isCardNumberError()) {
                        setCardNumberError({
                            message: e.getMessage(),
                        });
                    }

                    if (e.isExpError()) {
                        setExpirationError({
                            message: e.getMessage(),
                        });
                    }

                    if (e.isCvvError()) {
                        setCvvError({
                            message: e.getMessage(),
                        });
                    }

                    if (e.isCardHolderError()) {
                        setCardHolderError({
                            message: e.getMessage(),
                        });
                    }
                }

                if (e instanceof Error) {
                    onError(e.message);
                }
            }

            return null;
        }
    }), [cardHolder, cardholderRef]);

    if (cvvOnly) {
        return (
            <>
                <CreditCardOutline
                    cvvOnly
                    cvvInput={
                        <DlocalInput
                            dlocalService={dlocalService}
                            type={DlocalFieldType.CVV_ONLY}
                            onError={setCvvError}
                        />
                    }
                    cvvError={cvvError?.message}
                />
            </>
        );
    }

    return (
        <CenterLoader
            title={t('features.CreditCard.loadingTitle')}
            overlay
            loading={!show}
        >
            <CreditCardOutline
                cardNumberInput={
                    <DlocalInput
                        dlocalService={dlocalService}
                        type={DlocalFieldType.PAN}
                        onError={setCardNumberError}
                    />
                }
                cardNumberError={cardNumberError?.message}
                expirationInput={
                    {
                        type: CreditCardViewType.COMMON,
                        children: (
                            <DlocalInput
                                dlocalService={dlocalService}
                                type={DlocalFieldType.EXPIRATION}
                                onError={setExpirationError}
                            />
                        ),
                        expirationError: expirationError?.message,
                    }
                }
                cvvInput={
                    <DlocalInput
                        dlocalService={dlocalService}
                        type={DlocalFieldType.CVV}
                        onError={setCvvError}
                    />
                }
                cvvError={cvvError?.message}
                cardHolderInput={
                    <CardHolder
                        ref={cardholderRef}
                        onError={setCardHolderError}
                        onChange={setCardHolder}
                    />
                }
                cardHolderError={cardHolderError?.message}
            />
        </CenterLoader>
    );
});

export default DlocalCard;

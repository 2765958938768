import { FC, useCallback, useEffect, useState } from 'react';

import { GQLErrorType } from '@/lib/errors/graphql-error';
import { getSessionErrorMessage } from '@/lib/errors/session';
import { FormValues } from '@/lib/payments/forms';
import { PaymentProviderName } from '@/lib/payments/providers';
import SDK from '@/lib/payments/sdk';
import DlocalService from '@/services/dlocal-service';
import CenterLoader from '@/shared/ui/layout/center-loader';

import { PaymentFormData } from '../index';

import DLocalBankCardView from './dlocal';
import DlocalSavedCardView from './dlocal/saved-card';

const DlocalBankCardController: FC<PaymentFormData> = ({ store, paymentInfo, services }) => {
    const { country } = paymentInfo;

    const [dlocalService, setDlocalService] = useState<DlocalService|undefined>();

    useEffect(() => {
        const sdk = new SDK(services.logsService);
        sdk.get(PaymentProviderName.Dlocal)
            .then((sdkRef) => {
                if (!store.selectedMethod.session?.dlocal) {
                    services.navigatorService.showFinalPage('error', {
                        message: getSessionErrorMessage(),
                    });

                    return;
                }

                const dlocal = new DlocalService(
                    paymentInfo.country,
                    sdkRef(store.selectedMethod.session.dlocal.apiKey),
                    services.logsService,
                );
                setDlocalService(dlocal);
            })
            .catch(event => {
                services.navigatorService.showFinalPage('error', {
                    message: event
                });
            });
    }, []);

    const handleSubmit = (formFillValues: FormValues): Promise<GQLErrorType> => {
        return services.topupService.topUp(
            store.amount,
            store.selectedMethod,
            formFillValues,
            country,
        );
    };

    const handleSavedTopUpClick = () => {
        services.analyticService.event('processing.topup_saved_card_pay.click', undefined);
    };

    const handleError = useCallback((error: string) => {
        services.navigatorService.showFinalPage('error', {
            message: error,
        });
    }, []);

    if (!dlocalService) {
        return <CenterLoader loading />;
    }

    {/*TODO https://indriver.atlassian.net/browse/PRC-2208*/}

    return store.selectedMethod.savedInstruments?.bankCard ? (
        <DlocalSavedCardView
            dlocalService={dlocalService}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            panValue={store.selectedMethod.savedInstruments?.bankCard[0].last4}
            cvvOnly={store.selectedMethod.cvvForSavedCards}
            formFields={store.selectedMethod.formFields}
            onTopUpClick={handleSavedTopUpClick}
            onSubmit={handleSubmit}
            onError={handleError}
        />
    ) : (
        <DLocalBankCardView
            dlocalService={dlocalService}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={store.selectedMethod.formFields}
            onSubmit={handleSubmit}
            onError={handleError}
        />
    );
};

export default DlocalBankCardController;

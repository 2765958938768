import { ValidationError } from '@/lib/forms/validation';

export type CreditCardForm<T> = {
    validate: () => boolean;
    getData: (extra?: Record<string, string>) => Promise<T | null>;
    setCardholderError: (error: ValidationError) => void;
    getCvvOnly?: () => Promise<string | null>;
}

type FocusableReference = {
    current: null | {
        focus: VoidFunction;
    }
}

export const focusOn = (element: FocusableReference) => {
    return () => {
        if (element.current) {
            element.current.focus();
        }
    };
};
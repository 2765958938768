import i18n from 'i18next';

import Text from '@/lib/format/text';
import Validation from '@/lib/forms/validation';
import { Countries } from '@/lib/locale/countries';
import {
    getBillingCityField,
    getCityField,
    getDocumentField,
    getEmailField,
    getFirstnameField,
    getLastnameField,
    getFullAddressField,
    getFullBillingAddressField,
    getHouseField,
    getPhoneField,
    getStateField,
    getStreetField,
    getUsernameField,
    getZipField,
} from '@/lib/payments/forms/fields';
import {
    getBillingAddressSection,
    getHomeAddressSection,
    getPersonalDetailsSection
} from '@/lib/payments/forms/sections';
import { PaymentType } from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import { PaymentMethod } from '@/services/payment-info-service';

class FormFieldsService {
    // https://indriver.atlassian.net/browse/PRC-2144
    public getFormFields = (
        method: PaymentMethod,
        country: Countries,
    ) => {
        switch (method.providerName) {
            case PaymentProviderName.Payrails:
                return this.payrails(method, country);
            case PaymentProviderName.Nuvei:
                return this.nuvei();
            case PaymentProviderName.Iyzico:
                return this.iyzico();
            case PaymentProviderName.Simpaisa:
                return this.simpaisa(method);
            case PaymentProviderName.Wooppay:
                return this.wooppay(method, country);
            case PaymentProviderName.Dlocal:
                return this.dlocal(method, country);
            case PaymentProviderName.Credorax:
                return this.credorax(method);
            case PaymentProviderName.OneVision:
                return this.onevision(method);
            case PaymentProviderName.Izi:
                return this.izi(method, country);
            case PaymentProviderName.Yuno:
                return this.yuno(method);
            case PaymentProviderName.Fawry:
                return this.fawry(method);
            default:
                return [];
        }
    };

    public getHidableFormFields = (method: PaymentMethod,) => {
        switch (method.providerName) {
            case PaymentProviderName.Iyzico:
                return this.iyzicoHidable();
            default:
                return undefined;
        }
    };

    public isFormless = (
        providerName: PaymentProviderName,
        formFields: ReturnType<this['getFormFields']>,
    ) => {
        // This is a smooth migration for formless payments
        // Now it works only for Payrails
        // https://indriver.atlassian.net/browse/PRC-2181
        if (formFields.length === 0) {
            if (providerName === PaymentProviderName.Payrails) {
                return true;
            }
        }

        return false;
    };

    private payrails = (
        method: PaymentMethod,
        country: Countries,
    ) => {
        if (method.paymentType === PaymentType.BANK_CARD) {
            return [
                getPersonalDetailsSection(
                    [
                        getDocumentField(country)
                    ]
                )
            ];
        }

        // https://indriver.slack.com/archives/C03TDLP7AQM/p1727101556899039
        switch (country) {
            case Countries.Egypt:
            case Countries.Pakistan:
            case Countries.India:
                return [
                    getPersonalDetailsSection(
                        [
                            getDocumentField(country)
                        ]
                    )
                ];
            default:
                return [];
        }
    };

    private nuvei = () => {
        return [
            getPersonalDetailsSection([
                getEmailField()
            ])
        ];
    };

    private iyzico = () => {
        return [
            getPersonalDetailsSection([getDocumentField(Countries.Turkey)]),
            getHomeAddressSection([
                getCityField({
                    validators: [
                        Validation.required,
                        Validation.stringSizeValidator(1, 50),
                    ],
                }),
                getFullAddressField({
                    validators: [
                        Validation.required,
                        Validation.stringSizeValidator(1, 255),
                    ],
                }),
            ]),
        ];
    };

    private iyzicoHidable = () => {
        return {
            label: i18n.t('services.formBuilder.sections.sameAddressLabel'),
            fields: [
                getBillingAddressSection([
                    getBillingCityField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(1, 50),
                        ],
                    }),
                    getFullBillingAddressField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(1, 255),
                        ],
                    }),
                ]),
            ],
        };
    };

    private simpaisa = (method: PaymentMethod) => {
        if (method.paymentType === PaymentType.PROVIDER_CONTROLLED) {
            return [
                getPersonalDetailsSection([
                    getPhoneField(
                        {
                            mask:'+{0}00000000000000',
                            validators: [Validation.required]
                        }
                    )
                ])
            ];
        }

        return [];
    };

    private wooppay = (method: PaymentMethod, country: Countries) => {
        if (method.paymentType === PaymentType.PHONE_BALANCE) {
            switch (country) {
                case Countries.Kazakhstan:
                    return [
                        getPersonalDetailsSection([
                            getPhoneField({
                                mask: '+{7}(000)000-00-00',
                            }),
                        ]),
                    ];

                default:
                    return [
                        getPersonalDetailsSection([
                            getPhoneField(),
                        ]),
                    ];
            }
        }

        return [];
    };

    private dlocal = (method: PaymentMethod, country: Countries) => {
        if (method.paymentType === PaymentType.BANK_CARD) {
            if (method.savedInstruments?.bankCard) {
                switch (country) {
                    case Countries.SouthAfrica:
                        return [
                            getPersonalDetailsSection([
                                getEmailField({ formatter: Text.replaceIndriverEmail })
                            ])];
                    default:
                        return [
                            getPersonalDetailsSection([
                                getDocumentField(country),
                                getEmailField({ formatter: Text.replaceIndriverEmail })
                            ])
                        ];
                }
            }

            switch (country) {
                case Countries.India:
                    return [
                        getPersonalDetailsSection([
                            getEmailField({ formatter: Text.replaceIndriverEmail }),
                            getDocumentField(country),
                        ]),
                        getBillingAddressSection([
                            getStateField(),
                            getCityField(),
                            getZipField(),
                            getStreetField(),
                            getHouseField(),
                        ]),
                    ];
                case Countries.SouthAfrica:
                    if (method.isCustomerSaved) {
                        return [
                            getPersonalDetailsSection([
                                getEmailField({ formatter: Text.replaceIndriverEmail }),
                            ]),
                        ];
                    }

                    return [
                        getPersonalDetailsSection([
                            getEmailField({ formatter: Text.replaceIndriverEmail }),
                            getStateField(),
                            getCityField(),
                            getStreetField(),
                            getHouseField(),
                            getZipField(),
                        ]),
                    ];
                case Countries.Morocco:
                    return [getPersonalDetailsSection([
                        getEmailField({ formatter: Text.replaceIndriverEmail }),
                    ])];
                default:
                    return [
                        getPersonalDetailsSection([
                            getEmailField({ formatter: Text.replaceIndriverEmail }),
                            getDocumentField(country),
                        ]),
                    ];
            }
        }

        if (
            method.paymentType === PaymentType.WALLET ||
            method.paymentType === PaymentType.BANK_TRANSFER
        ) {
            switch (country) {
                case Countries.SouthAfrica:
                    if (method.isCustomerSaved) {
                        return [
                            getPersonalDetailsSection([
                                getUsernameField(),
                                getEmailField(),
                            ]),
                        ];
                    }

                    return [
                        getPersonalDetailsSection([
                            getUsernameField(),
                            getStateField(),
                            getCityField(),
                            getStreetField(),
                            getHouseField(),
                            getZipField(),
                            getEmailField(),
                        ]),
                    ];
                default:
                    return [
                        getPersonalDetailsSection([
                            getUsernameField(),
                            getDocumentField(country),
                            getEmailField(),
                        ]),
                    ];
            }
        }

        if (method.paymentType === PaymentType.CASH) {
            switch (country) {
                case Countries.Morocco:
                    return [
                        getPersonalDetailsSection([
                            getUsernameField(),
                            getEmailField(),
                        ]),
                    ];
                default:
                    return [
                        getPersonalDetailsSection([
                            getUsernameField(),
                            getEmailField(),
                            getDocumentField(country),
                        ]),
                    ];
            }
        }

        return [];
    };

    private credorax = (method: PaymentMethod) => {
        if (method.paymentType === PaymentType.BANK_CARD) {
            if (method.savedInstruments?.bankCard) {
                return [
                    getPersonalDetailsSection([
                        getFirstnameField(),
                        getLastnameField(),
                    ]),
                ];
            }

            return [
                getBillingAddressSection([
                    getCityField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(3, 30),
                        ]
                    }),
                    getStreetField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(4, 50),
                        ]
                    }),
                    getHouseField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(1, 16),
                        ]
                    }),
                    getZipField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(1, 9),
                            Validation.zipCode,
                        ]
                    }),
                    getEmailField(),
                ]),
            ];
        }

        return [];
    };

    private onevision = (method: PaymentMethod) => {
        if (method.paymentType === PaymentType.BANK_CARD) {
            return [
                getPersonalDetailsSection([
                    getPhoneField({
                        mask: '+{000} 000 000 000',
                    }),
                ]),
            ];
        }

        return [];
    };

    private izi = (method: PaymentMethod, country: Countries) => {
        if (method.paymentType === PaymentType.BANK_CARD) {
            return [
                getPersonalDetailsSection([
                    getDocumentField(country),
                ]),
            ];
        }

        if (method.paymentType === PaymentType.CASH) {
            return [
                getPersonalDetailsSection([
                    getDocumentField(country),
                ]),
            ];
        }

        return [];
    };

    private yuno = (method: PaymentMethod) => {
        if (method.paymentType === PaymentType.BANK_CARD) {
            return [
                getPersonalDetailsSection([
                    getEmailField({ formatter: Text.replaceIndriverEmail }),
                ]),
            ];
        }

        return [];
    };

    private fawry = (method: PaymentMethod) => {
        if (method.paymentType === PaymentType.CASH) {
            return [
                getPersonalDetailsSection([
                    getEmailField(),
                ]),
            ];
        }

        return [];
    };
}

export default FormFieldsService;

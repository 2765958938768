import { gql } from '@apollo/client';

import { UserAction } from './top-up-with-nuvei';

export type AuthorizeWithNuveiMutation = {
    input: AuthorizeWithNuveiInput;
}

export type AuthorizeWithNuveiResponse = {
    authorizeWithNuvei: {
        nextAction: UserAction;
    }
}

export type AuthorizeWithNuveiInput = {
    email: string;
    paymentID: string;
    providerSession: string;
    cardToken: string;
    cardHolderName: string;
}


export default gql(`
    mutation authorizeWithNuvei ($input: AuthorizeWithNuveiInput!){
        authorizeWithNuvei(
            input: $input
        ) {
            nextAction
        }
    }
`);
import i18n from 'i18next';

import Validation, { Validator } from '../../forms/validation';
import { Countries } from '../../locale/countries';

import { getDocumentMeta } from './documents';
import {
    FULL_ADDRESS_ID,
    AMOUNT_ID,
    FULL_BILLING_ADDRESS_ID,
    BILLING_CITY_ID,
    BILLING_HOUSE_ID,
    BILLING_STATE_ID,
    BILLING_STREET_ID,
    BILLING_ZIP_ID,
    CITY_ID,
    CONFIRMATION_CODE_ID,
    DOCUMENT_ID,
    EMAIL_ID,
    FIRSTNAME_ID,
    HOUSE_ID,
    LASTNAME_ID,
    PHONE_ID,
    STATE_ID,
    STREET_ID,
    USERNAME_ID,
    ZIP_ID,
} from './indentificators';
import { FormField } from './index';

type CreacteFieldOptions = {
    validators?: Validator[];
    mask?: string;
    formatter?: (value: string) => string;
};

type CreateFieldFunction = (options?: CreacteFieldOptions) => FormField;

export const getPhoneField: CreateFieldFunction = (options) => ({
    id: PHONE_ID,
    title: i18n.t('services.formBuilder.fields.phoneTitle'),
    mask: options?.mask,
    validators: options?.validators || [Validation.required],
});

export const getEmailField: CreateFieldFunction = (options) => ({
    id: EMAIL_ID,
    title: i18n.t('services.formBuilder.fields.emailTitle'),
    formatter: options?.formatter,
    validators: [Validation.required, Validation.email],
});

export const getAmountField: CreateFieldFunction = () => ({
    id: AMOUNT_ID,
    title: i18n.t('services.formBuilder.fields.amountTitle'),
    mask: Number,
    type: 'number',
    validators: [Validation.required],
});

export const getStateField: CreateFieldFunction = (options) => ({
    id: STATE_ID,
    title: i18n.t('services.formBuilder.fields.stateTitle'),
    validators: options?.validators || [Validation.required],
});

export const getBillingStateField: CreateFieldFunction = (options) => ({
    id: BILLING_STATE_ID,
    title: i18n.t('services.formBuilder.fields.stateTitle'),
    validators: options?.validators || [Validation.required],
});

export const getCityField: CreateFieldFunction = (options) => ({
    id: CITY_ID,
    title: i18n.t('services.formBuilder.fields.cityTitle'),
    validators: options?.validators || [Validation.required],
});

export const getBillingCityField: CreateFieldFunction = (options) => ({
    id: BILLING_CITY_ID,
    title: i18n.t('services.formBuilder.fields.cityTitle'),
    validators: options?.validators || [Validation.required],
});

export const getStreetField: CreateFieldFunction = (options) => ({
    id: STREET_ID,
    title: i18n.t('services.formBuilder.fields.streetTitle'),
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(1, 50)],
});

export const getBillingStreetField: CreateFieldFunction = (options) => ({
    id: BILLING_HOUSE_ID,
    title: i18n.t('services.formBuilder.fields.streetTitle'),
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(1, 50)],
});

export const getHouseField: CreateFieldFunction = (options) => ({
    id: HOUSE_ID,
    title: i18n.t('services.formBuilder.fields.houseTitle'),
    mask: Number,
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(1, 16)],
});

export const getBillingHouseField: CreateFieldFunction = (options) => ({
    id: BILLING_ZIP_ID,
    title: i18n.t('services.formBuilder.fields.houseTitle'),
    mask: Number,
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(1, 16)],
});

export const getZipField: CreateFieldFunction = (options) => ({
    id: ZIP_ID,
    title: i18n.t('services.formBuilder.fields.zipTitle'),
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(1, 9)],
});

export const getBillingZipField: CreateFieldFunction = (options) => ({
    id: BILLING_STREET_ID,
    title: i18n.t('services.formBuilder.fields.zipTitle'),
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(1, 9)],
});

export const getFirstnameField: CreateFieldFunction = () => ({
    id: FIRSTNAME_ID,
    title: i18n.t('services.formBuilder.fields.firstnameTitle'),
    validators: [Validation.required],
});

export const getLastnameField: CreateFieldFunction = () => ({
    id: LASTNAME_ID,
    title: i18n.t('services.formBuilder.fields.lastnameTitle'),
    validators: [Validation.required],
});

export const getUsernameField: CreateFieldFunction = () => ({
    id: USERNAME_ID,
    title: i18n.t('services.formBuilder.fields.usernameTitle'),
    validators: [Validation.required],
});

export const getFullAddressField: CreateFieldFunction = (options) => ({
    id: FULL_ADDRESS_ID,
    title: i18n.t('services.formBuilder.fields.fullAddressTitle'),
    validators: options?.validators || [Validation.required],
});

export const getFullBillingAddressField: CreateFieldFunction = (options) => ({
    id: FULL_BILLING_ADDRESS_ID,
    title: i18n.t('services.formBuilder.fields.fullAddressTitle'),
    validators: options?.validators || [Validation.required],
});

export const getConfirmationCodeField: CreateFieldFunction = () => ({
    id: CONFIRMATION_CODE_ID,
    title: i18n.t('services.formBuilder.fields.confirmationCodeTitle'),
    validators: [Validation.required],
});

export const getDocumentField = (country: Countries) => {
    return {
        id: DOCUMENT_ID,
        ...getDocumentMeta(country),
    };
};

import i18n from 'i18next';

import { isValidByLuhnAlgorithm } from './lib/is';

export type ValidationError = { message: string } | undefined;
export type Validator = (value: string) => ValidationError;

enum REGEX {
    // eslint-disable-next-line max-len
    EMAIL = '^(([a-zA-Z0-9\\._-]+){0,1}(\\+){0,1}([a-zA-Z0-9\\._-]+){0,1}([a-zA-Z0-9_-]+){1}@([a-zA-Z0-9-\\.]+)\\.([a-zA-Z]{2,30}))$',
}

export default class Validation {
    public static validate(
        value: string,
        validators: Validator[]
    ): ValidationError {
        let err;
        if (validators) {
            for (let i = 0; i < validators.length; i++) {
                err = validators[i](value);
                if (err) {
                    return err;
                }
            }
        }

        return err;
    }

    public static required(value: string): ValidationError {
        const pattern = /^\s+$/;

        if (value.replace(pattern, '') !== '') {
            return undefined;
        }

        return {
            message: i18n.t('lib.validation.required'),
        };
    }

    public static optional(validators: Validator[]) {
        return (value: string): ValidationError => {
            if (value.length === 0) {
                return undefined;
            }

            return Validation.validate(value, validators);
        };
    }

    public static or(validators: Validator[]) {
        return (value: string): ValidationError => {
            let err = undefined;
            for (let i = 0; i < validators.length; i++) {
                const res = validators[i](value);
                if (res === undefined) {
                    return undefined;
                }

                // First error show
                if (err === undefined && res !== undefined) {
                    err = res;
                }
            }

            return err;
        };
    }

    public static stringSizeValidator(from: number, to?: number) {
        return (value: string): ValidationError => {
            if (value.length < from) {
                return {
                    message: i18n.t('lib.validation.stringSizeValidator.greater', {
                        count: from,
                    }),
                };
            }

            if (to === undefined) {
                return;
            }

            if (value.length > to) {
                return {
                    message: i18n.t('lib.validation.stringSizeValidator.less', {
                        count: to,
                    }),
                };
            }

            return;
        };
    }

    public static minMaxValue(min: number, max: number, currency?: string) {
        return (value: string): ValidationError => {
            const num = parseInt(value, 10);

            if (isNaN(num) || num < min || num > max) {
                return {
                    message: i18n.t('lib.validation.numberRangeValidator.notInRange', {
                        currency: currency || '',
                        min,
                        max,
                    }),
                };
            }

            return;
        };
    }

    public static email(value: string): ValidationError {
        if (!new RegExp(REGEX.EMAIL).test(value.trim())) {
            return {
                message: i18n.t('lib.validation.email'),
            };
        }

        return undefined;
    }

    public static cardNumber(value: string): ValidationError {
        const number = value.replace(/\D/g, '');

        if (!isValidByLuhnAlgorithm(number)) {
            return {
                message: i18n.t('lib.validation.cardNumber'),
            };
        }

        return undefined;
    }

    public static expirationMonth(value: string): ValidationError {
        const month = Number(value);
        if (month > 12 || month <= 0) {
            return {
                message: i18n.t('lib.validation.expirationDate'),
            };
        }

        return undefined;
    }

    public static expirationYear = (value: string): ValidationError => {
        const currentDate = new Date();
        const yearToCompare = 2000 + Number(value);
        const currentYear = currentDate.getFullYear();

        const date = new Date(
            yearToCompare,
            currentDate.getMonth(),
            currentDate.getDate()
        );

        const shiftedDate = new Date(
            currentDate.getFullYear() + 50,
            currentDate.getMonth(),
            currentDate.getDate()
        );

        if (!date.valueOf() || date > shiftedDate) {
            return {
                message: i18n.t('lib.validation.expirationDate'),
            };
        }

        if (currentYear > yearToCompare) {
            return {
                message: i18n.t('lib.validation.dateExpired'),
            };
        }

        return undefined;
    };

    public static expirationDate = (month: string, year: string) => {
        return (): ValidationError => {
            const date = new Date(+`20${year}`, +month);
            const currentDate = new Date();

            if (currentDate > date) {
                return {
                    message: i18n.t('lib.validation.dateExpired'),
                };
            }

            return undefined;
        };
    };

    public static cvv = (isAmex: boolean) => {
        return (value: string): ValidationError => {
            if (!/^\d+$/.test(value)) {
                return {
                    message: i18n.t('lib.validation.cvv'),
                };
            }

            if (isAmex) {
                if (value.length === 4) {
                    return undefined;
                }

                return {
                    message: i18n.t('lib.validation.cvcRequired', {
                        value: '••••'
                    }),
                };
            }

            return value.length === 3? undefined:  {
                message: i18n.t('lib.validation.cvcRequired', {
                    value: '•••'
                }),
            };
        };
    };

    public static cardHolder = (value: string): ValidationError => {
        if (new RegExp(/^[a-zA-Z\-\s'.]+$/).test(value)) {
            return undefined;
        }

        return {
            message: i18n.t('lib.validation.cardHolder'),
        };
    };

    public static firstAndLastnameCardholder = (value: string): ValidationError => {
        const splittedValues = value.split(' ');
        const name = splittedValues.shift();
        const nameError = Validation.cardHolder(name || '');
        const surnameError = splittedValues
            .join(' ')
            .trim().length >= 2 ? undefined : { message: i18n.t('lib.validation.cardHolder') };

        return nameError || surnameError;
    };

    public static zipCode = (value: string): ValidationError => {
        if (new RegExp(/^[a-zA-Z0-9]{1,9}/).test(value)) {
            return undefined;
        }

        return {
            message: i18n.t('lib.validation.zipCode'),
        };
    };

    public static hasErrors = (errors: ValidationError[]): boolean => {
        return Boolean(errors.find((e) => Boolean(e)));
    };
}

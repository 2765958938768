import * as Sentry from '@sentry/react';
import { Component } from 'react';

import { getCurrencyCode } from '@/lib/locale';
import { PaymentMethod } from '@/services/payment-info-service';
import CenterLoader from '@/shared/ui/layout/center-loader';

import { AppData } from '../../index';

import AutoTopupSettingsForm from './auto-topup-settings-form';
import PaymentForm from './payment-form';
import PaymentInfo from './payment-info';
import PaymentPointsView from './payment-points-view';

export type MainStore = {
    store: {
        amount: string;
        selectedMethod: PaymentMethod;
    };
    onHideGoBack: VoidFunction;
}

type MainState = {
    loading: boolean;
    amount: string;
    selectedMethod: PaymentMethod | null;
    selectedMethodIndex: number | null;
    showAutoTopupView: boolean;
    mapSrcToShow?: string;
    hideGoBack: boolean;
}

class Main extends Component<AppData, MainState> {
    constructor(props: AppData) {
        super(props);

        const { paymentMethods } = this.props.paymentInfo;

        let selectedMethodIndex: number | null = null;
        if (this.props.paymentInfo.defaultMethod) {
            selectedMethodIndex = this.props.paymentInfo.defaultMethod;
        } else if (paymentMethods.length === 1) {
            selectedMethodIndex = 0;
        }

        const selectedMethod = selectedMethodIndex ? paymentMethods[selectedMethodIndex] : null;

        this.state = {
            loading: false,
            amount: '',
            selectedMethodIndex,
            selectedMethod,
            showAutoTopupView: false,
            mapSrcToShow: undefined,
            hideGoBack: false,
        };

        // todo describe better what that flow is
        this.props.services.paymentInfoService.addEventListener('onPaymentInfoUpdate', () => {
            this.setState({
                selectedMethod: null,
            });
        });

        // todo wait for platform https://indriver.atlassian.net/browse/PRC-2776
        try {
            const jwt = props.services.authorizationService.getJWT();
            if (jwt.getToken().city_id === 5831) { // Turkey Batman
                props.services.ipcService.addEventListener('onBackArrowLeftClick', this.handleGoBack);
                props.services.ipcService.setupNavgation();
            }
        } catch (e) {
            Sentry.captureException(e);
        }
    }

    public componentDidMount() {
        window.access('mainviewmounted');
    }

    private handleSelectedMethodIndexChange = (selectedMethodIndex: number | null) => {
        this.setState({ selectedMethodIndex });
    };

    /**
     * Temporary hack with goBack button while native nav of mobile is not ready
     * Please see ticket https://indriver.atlassian.net/browse/PL-6344
     * Also https://indriver.atlassian.net/browse/PRC-2776
     */
    private handleGoBack = () => {
        this.setState({
            mapSrcToShow: '',
            selectedMethod: null,
            showAutoTopupView: false,
            hideGoBack: false,
        });
    };

    /**
     * Temporary hack with goBack button while native nav of mobile is not ready
     * Please see ticket https://indriver.atlassian.net/browse/PL-6344
     * Also https://indriver.atlassian.net/browse/PRC-2776
     */
    private handleHideGoBack = () => {
        this.setState({
            hideGoBack: true,
        });
    };

    private handlePaymentMethod = (amount: string, selectedMethod: PaymentMethod) => {
        const { analyticService, viewService } = this.props.services;

        analyticService.event('processing.topup_payment_option.click', {
            payment_option: selectedMethod.paymentType,
            provider: selectedMethod.providerName,
        });

        if (viewService.isInFrameMethod(selectedMethod)) {
            return;
        }

        if (selectedMethod.isFormless) {
            this.setState({ loading: true });
            this.props.services.topupService.topUp(
                amount,
                selectedMethod,
                {},
                this.props.paymentInfo.country,
            );

            return;
        }

        // that means redirect to payment form
        this.setState({
            amount,
            selectedMethod,
        });
    };

    private handleAutoTopupShow = () => {
        this.props.services.analyticService.event('processing.topup_pay_auto.click', undefined);

        this.setState({ showAutoTopupView: true });
    };

    private handlePaymentPointsClick = (mapSrc: string) => {
        this.setState({ mapSrcToShow: mapSrc });
    };

    public render() {
        const { amount, selectedMethod } = this.state;
        const { paymentInfo, services } = this.props;

        if (this.state.loading) {
            return <CenterLoader loading />;
        }

        if (this.state.showAutoTopupView) {
            return (
                <AutoTopupSettingsForm
                    autoTopupInfo={paymentInfo.autoTopup}
                    currencyCode={getCurrencyCode(paymentInfo.country)}
                    services={services}
                    onGoBack={this.handleGoBack}
                />
            );
        }

        if (this.state.mapSrcToShow) {
            return (
                <PaymentPointsView
                    src={this.state.mapSrcToShow}
                    onGoBack={this.handleGoBack}
                />
            );
        }

        if (selectedMethod) {
            const store: MainStore = {
                store: {
                    amount,
                    selectedMethod: selectedMethod
                },
                onHideGoBack: this.handleHideGoBack,
            };

            return (
                <PaymentForm
                    method={selectedMethod}
                    hideGoBack={this.state.hideGoBack}
                    onGoBack={this.handleGoBack}
                    {...this.props}
                    {...store}
                />
            );
        }

        return (
            <PaymentInfo
                defaultAmount={paymentInfo.defaultAmount}
                selectedMethodIndex={this.state.selectedMethodIndex}
                country={paymentInfo.country}
                currencyCode={getCurrencyCode(paymentInfo.country)}
                paymentMethods={paymentInfo.paymentMethods}
                autoTopupInfo={paymentInfo.autoTopup}
                suggestedAmounts={paymentInfo.suggestedAmounts}
                services={services}
                onSelectedMethodIndexChange={this.handleSelectedMethodIndexChange}
                onPaymentMethod={this.handlePaymentMethod}
                onAutoTopupClick={this.handleAutoTopupShow}
                onPaymentPointsClick={this.handlePaymentPointsClick}
            />
        );
    }
}

export default Main;

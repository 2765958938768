import i18n from 'i18next';

import ErrorStatus from './status';

class PaymentProviderError {
    static INTERNAL_SERVER_ERROR_CODE = 'INTERNAL_SERVER_ERROR_CODE';
    static PROVIDER_ERROR_CODE = 'PROVIDER_ERROR_CODE';

    public getTitleFor(code: string): string {
        switch (code) {
            case PaymentProviderError.INTERNAL_SERVER_ERROR_CODE:
                return i18n.t('lib.errors.internalServerError');
            case PaymentProviderError.PROVIDER_ERROR_CODE:
                return i18n.t('lib.errors.internalServerError');
            default:
                return i18n.t('lib.errors.defaultTitle');
        }
    }

    public internalServer = () => ({
        title: i18n.t('lib.errors.defaultTitle'),
        messages: [i18n.t('lib.errors.defaultMessage')],
        errorStatus: ErrorStatus.PAYMENT_PROVIDER_INTERNAL_SERVER_ERROR,
    });

    public common = () => ({
        title: i18n.t('lib.errors.defaultTitle'),
        messages: [i18n.t('lib.errors.defaultMessage')],
        errorStatus: ErrorStatus.COMMON_INTERNAL_ERROR,
    });

    public unauhthorized = () => ({
        title: i18n.t('lib.errors.unauthorized'),
        messages: [i18n.t('lib.errors.unauthorizedDescription')],
        errorStatus: ErrorStatus.PAYMENT_PROVIDER_401,
    });

    public providerError = () => ({
        title: i18n.t('lib.errors.rejectedByProviderLabel'),
        messages: [i18n.t('lib.errors.rejectedByProviderMessage')],
        errorStatus: ErrorStatus.PROVIDER_ERROR_CODE
    });
}

export default PaymentProviderError;

import { ChatAsk, ChatOutline, Mail, PhoneAccept } from '@indriver/mireska';
import { Cell } from '@indriver/nova';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '@/shared/ui/core/modal';

import Resources from '../../resources';

import * as UI from './ui';

type SupportProps = {
    emails: string[];
    phones: string[];
    onOpenEmail: (email: string) => void;
    onOpenPhone: (phone: string) => void;
    onOpenSupportChat: VoidFunction;
}

const Support: FC<SupportProps> = ({
    onOpenSupportChat,
    onOpenEmail,
    onOpenPhone,
    emails,
    phones
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleActionClick = () => {
        setOpen(true);
    };

    const handleBottomClose = () => {
        setOpen(false);
    };

    const supportOtherAnswerTime = t('features.Support.otherAnswerTime');
    const chatTitle = t('features.Support.chat');

    return (
        <UI.Wrapper>
            <Cell
                data-id={Resources.test.howToTopUpForm.support}
                dir={Resources.getDir()}
                prefix={
                    <UI.Icon>
                        <ChatOutline size={24} />
                    </UI.Icon>
                }
                size='m'
                design='default'
                withChevron
                onClick={handleActionClick}
                as='button'
            >
                <UI.CellContent>
                    <UI.Text>
                        <UI.Header>{t('features.Support.title')}</UI.Header>
                        <UI.Description>{t('features.Support.description')}</UI.Description>
                    </UI.Text>
                </UI.CellContent>
            </Cell>

            <Modal
                title={t('features.Support.title')}
                rootElementId='support-modal-root-element'
                open={open}
                onClose={handleBottomClose}
            >
                <UI.BodyWrapper>
                    <UI.BodyDescription>
                        {supportOtherAnswerTime}
                    </UI.BodyDescription>

                    {
                        phones.map(phone => (
                            <Cell
                                key={phone}
                                data-id={Resources.test.howToTopUpForm.phone}
                                dir={Resources.getDir()}
                                prefix={<PhoneAccept size={24} />}
                                withChevron
                                size='m'
                                design='default'
                                as='button'
                                onClick={() => onOpenPhone(phone)}
                            >
                                <UI.CellContent>
                                    <UI.Text>{phone}</UI.Text>
                                </UI.CellContent>
                            </Cell>
                        ))
                    }

                    {
                        emails.map(email => (
                            <Cell
                                key={email}
                                data-id={Resources.test.howToTopUpForm.email}
                                dir={Resources.getDir()}
                                prefix={<Mail size={24} />}
                                withChevron
                                size='m'
                                design='default'
                                onClick={() => onOpenEmail(email)}
                                as='button'
                            >
                                <UI.CellContent>
                                    <UI.Text>{email}</UI.Text>
                                </UI.CellContent>
                            </Cell>
                        ))
                    }

                    <Cell
                        data-id={Resources.test.howToTopUpForm.chat}
                        dir={Resources.getDir()}
                        prefix={<ChatAsk size={24} />}
                        size='m'
                        design='default'
                        withChevron
                        onClick={onOpenSupportChat}
                        as='button'
                    >
                        <UI.CellContent>
                            <UI.Text>{chatTitle}</UI.Text>
                        </UI.CellContent>
                    </Cell>

                    <Cell
                        data-id={Resources.test.howToTopUpForm.email}
                        dir={Resources.getDir()}
                        prefix={<Mail size={24} />}
                        size='m'
                        design='default'
                        withChevron
                        onClick={() => onOpenEmail(Resources.contacts.mainSupportEmail)}
                        as='button'
                    >
                        <UI.CellContent>
                            <UI.Text>{Resources.contacts.mainSupportEmail}</UI.Text>
                        </UI.CellContent>
                    </Cell>
                </UI.BodyWrapper>
            </Modal>
        </UI.Wrapper>
    );
};

export default Support;

import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import HowToTopUpInfo from '@/features/how-to-top-up-info';
import PaycodeView from '@/features/paycode-view';


type OnoiCashProps = {
    referenceNumber: string;
    onCopy: (paycode: string, title: string) => void;
}

const OnoiCash: FC<OnoiCashProps> = ({ referenceNumber, onCopy }) => {
    const { t } = useTranslation();

    const handleCopyPaycode = useCallback(() => {
        onCopy(referenceNumber, t('views.PaymentMethods.Cash.Izi.voucherCopiedLabel'));
    }, [referenceNumber, onCopy]);

    return (
        <PaycodeView
            title={t('views.PaymentMethods.Cash.Onoi.referenceTitle')}
            paycode={referenceNumber}
            onClick={handleCopyPaycode}
        >
            <HowToTopUpInfo
                instructionsTitle={t('views.PaymentMethods.Cash.Onoi.instructionTitle')}
                steps={
                    [
                        t('views.PaymentMethods.Cash.Onoi.instructionStep1'),
                        t('views.PaymentMethods.Cash.Onoi.instructionStep2'),
                    ]
                }
                descriptionText={t('views.PaymentMethods.Cash.Onoi.instructionDescription')}
            />
        </PaycodeView>
    );
};

export default OnoiCash;

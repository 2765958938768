import { Button } from '@indriver/nova';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getIdentificationDocumentPicture } from '@/lib/locale';
import { Countries } from '@/lib/locale/countries';
import { HintType } from '@/lib/payments/forms/hints';
import Modal from '@/shared/ui/core/modal';

import Resources from '../../resources';

import * as UI from './ui';

type HintProps = {
    type: HintType | undefined;
    country: Countries;
    onClose: VoidFunction;
}

const getHintContent = (country: Countries, type?: HintType) => {
    const { t } = useTranslation();

    switch (type) {
        case HintType.BILLING_ADDRESS:
            return {
                title: t('features.Hint.billingTitle'),
                getBody: () => (
                    <UI.InfoContent>
                        <span>{t('features.Hint.billingContent')}</span>
                        <br /><br />
                        <span>{t('features.Hint.billingContent2')}</span>
                    </UI.InfoContent>
                )
            };
        case HintType.DOCUMENT:
            return {
                title: t('features.Hint.idCaption'),
                getBody: () => (
                    <UI.ContentWrapper>
                        <UI.ImageContainer>
                            <UI.Image src={getIdentificationDocumentPicture(country)} />
                        </UI.ImageContainer>

                        <UI.Text>
                            {t('features.Hint.idDescription')}
                        </UI.Text>
                    </UI.ContentWrapper>
                )
            };
        default:
            return undefined;
    }
};

const Hint: FC<HintProps> = ({
    type,
    country,
    onClose
}) => {
    const { t } = useTranslation();
    const content = getHintContent(country, type);

    if (!content) {
        return null;
    }

    return (
        <Modal
            open
            title={content.title}
            rootElementId='hint-modal-root-element'
            onClose={onClose}
        >
            <UI.Wrapper>
                {content.getBody()}

                <Button
                    data-id={Resources.test.submitHint}
                    size='l'
                    design='secondary'
                    onClick={onClose}
                >
                    {t('features.Hint.submitCaption')}
                </Button>
            </UI.Wrapper>
        </Modal>
    );
};

export default Hint;

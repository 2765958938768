import { WarningTrOutline } from '@indriver/mireska';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { InformationScreen } from '@/features/information-screen';
import DisplayedError from '@/lib/errors/displayed-error';
import NavigatorService from '@/services/navigator-service';

type ProfileErrorPageProps = {
    navigatorService: NavigatorService;
}

const ProfileErrorPage: FC<ProfileErrorPageProps> = ({ navigatorService }) => {
    const { t } = useTranslation();

    const displayedError = DisplayedError.parse(navigatorService.getQuery());

    useEffect(() => {
        window.access('profileerrorpage');
    }, []);

    const handleClick = useCallback(() => {
        navigatorService.goProfile();
    }, []);

    return (
        <InformationScreen
            type='warning'
            heading={t('pages.profileErrorPage.title')}
            description={displayedError.messages[0]}
            errorStatus={displayedError.errorStatus}
            icon={WarningTrOutline}
            buttonText={t('pages.profileErrorPage.submitButton')}
            onClickButton={handleClick}
        />
    );
};

export default ProfileErrorPage;

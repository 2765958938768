import i18n from 'i18next';

import PaymentProviderError from '@/lib/errors/payment-provider-error';

export type PaymentProviderI18NKeys = keyof typeof PaymentProviderI18N['keyMap'];

export type PaymentProviderI18NError = {
    i18nKey: PaymentProviderI18NKeys,
    i18nArgs: Record<string, string | number> & { field: string },
}

type LocalizedError = {
    title: string;
    messages: string[];
}

export type MessageI18n = {
    i18nKey: PaymentProviderI18NKeys;
    i18nArgs: {
        key: string;
        value: string
    }[] | null;
}

class PaymentProviderI18N {
    static keyMap = {
        provider_error_code_text: 'paymentProvider.providerErrorCodeText',
        internal_server_error_code_text: 'paymentProvider.internalServerErrorCodeText',
        provider_client_error_code_text: 'paymentProvider.providerClientErrorCodeText',
        provider_payment_rejected_error_text: 'paymentProvider.providerPaymentRejectedErrorText',
        // eslint-disable-next-line max-len
        provider_payment_rejected_error_contact_bank_text: 'paymentProvider.providerPaymentRejectedErrorContactBankText',
        // eslint-disable-next-line max-len
        provider_payment_rejected_error_enter_valid_card_text: 'paymentProvider.providerPaymentRejectedErrorEnterValidCardText',
        provider_payment_rejected_error_enter_valid_security_code_text:
            'providerPaymentRejectedErrorEnterValidSecurityCodeText',
        // eslint-disable-next-line max-len
        provider_payment_rejected_error_insufficient_funds_text: 'paymentProvider.providerPaymentRejectedErrorInsufficientFundsText',
        yuno_method_disabled_by_limits: 'paymentProvider.methodDisabledCauseLimits',
        method_disabled_cause_limits: 'paymentProvider.methodDisabledCauseLimits',
        verification_error_code_text: 'paymentProvider.verificationErrorCodeText',
        validation_length_too_long: 'paymentProvider.validationLengthTooLong',
        validation_length_too_short: 'paymentProvider.validationLengthTooShort',
        validation_length_invalid: 'paymentProvider.validationLengthInvalid',
        validation_length_out_of_range: 'paymentProvider.validationLengthOutOfRange',
        validation_length_empty_required: 'paymentProvider.validationLengthEmptyRequired',
        validation_match_invalid: 'paymentProvider.validationMatchInvalid',
        validation_min_greater_equal_than_required: 'paymentProvider.validationMinGreaterEqualThanRequired',
        validation_max_less_equal_than_required: 'paymentProvider.validationMaxLessEqualThanRequired',
        validation_min_greater_than_required: 'paymentProvider.validationMinGreaterThanRequired',
        validation_max_less_than_required: 'paymentProvider.validationMaxLessThanRequired',
        validation_required: 'paymentProvider.validationRequired',
        validation_nil_or_not_empty_required: 'paymentProvider.validationNilOrNotEmptyRequired',
        validation_is_email: 'paymentProvider.validationIsEmail',
        dlocal_payer_document_invalid_error_text: 'paymentProvider.dlocalPayerDocumentInvalidErrorText',
        balance_is_not_enough: 'paymentProvider.balanceIsNotEnough',
        transfer_amount_wrong: 'paymentProvider.transferAmountWrong',
        operation_limit_exceed: 'paymentProvider.operationLimitExceed',
        money_transfer_forbidden: 'paymentProvider.moneyTransferForbidden',
        bill_is_blocked: 'paymentProvider.billIsBlocked',
        wrong_phone_number: 'paymentProvider.wrongPhoneNumber',
        wrong_confirmation_code: 'paymentProvider.wrongConfirmationCode',
        topup_limit_exceeded: 'paymentProvider.topUpLimitExceeded',
        insufficient_funds: 'paymentProvider.insufficientFunds',
        city_is_not_set: 'paymentProvider.cityIsNotSet',
        last_name_is_required: 'paymentProvider.lastNameIsRequired',
        email_is_not_valid: 'paymentProvider.emailIsNotValid',
        provider_returned_empty_list: 'paymentProvider.providerReturnedEmptyList',
        error_text_app_update_required: 'paymentProvider.errorTextAppUpdateRequired',
        error_text_trust_and_safety_blacklist: 'paymentProvider.errorTextTrustAndSafetyBlacklist',
        error_text_antifraud_default: 'paymentProvider.errorTextAntifraudDefault',
    };

    private getKey = (key: PaymentProviderI18NKeys): string => {
        return PaymentProviderI18N.keyMap[key];
    };

    public localizeMessage = (msg: MessageI18n): string => {
        const buildArgs = (msg.i18nArgs || []).reduce<Record<string, string>>((args, keyValue) => {
            args[keyValue.key] = keyValue.value;

            return args;
        }, {});

        return i18n.t(this.getKey(msg.i18nKey), buildArgs) || msg.i18nKey;
    };

    public localizeError = (data: any): LocalizedError => {
        const code = data?.code || '';

        const ppError = new PaymentProviderError();
        const title = ppError.getTitleFor(code);

        const messages = (data?.messages || []).map((message: MessageI18n) => {
            return this.localizeMessage(message);
        });

        return {
            title,
            messages,
        };
    };

    public getErrorText = (error: PaymentProviderI18NError, defaultKey: string): string => {
        const i18NextKey = this.getKey(error.i18nKey);

        if (!i18NextKey) {
            return i18n.t(defaultKey) || '';
        }

        return i18n.t(i18NextKey, error.i18nArgs) || '';
    };
}

export default PaymentProviderI18N;

import { FC, useCallback, useEffect, useState } from 'react';

import { FormValues } from '@/lib/payments/forms';
import CenterLoader from '@/shared/ui/layout/center-loader';

import { PaymentFormData } from '../index';

import PayrailsBankCardView from './payrails';
import PayrailsSavedCardView from './payrails/saved-card';

import { PayrailsInitOptions } from '../../../../../../graphql/payrails';

const PayrailsBankCardController: FC<PaymentFormData> = ({
    store,
    paymentInfo,
    services,
}) => {
    const [initOptions, setInitOptions] = useState<PayrailsInitOptions | undefined>();

    useEffect(() => {
        services.paymentProviderService.process(async (req) => {
            const { data } = await req.getPayrailsInitOptions({
                instrumentID: store.selectedMethod.session?.payrails?.instrumentId || '',
            });

            setInitOptions(data?.payrailsInitOptions);
        });
    }, [store]);

    const handleSubmit = useCallback((formFillValues: FormValues) => {
        return services.topupService.topUp(
            store.amount,
            store.selectedMethod,
            formFillValues,
            paymentInfo.country,
        );
    }, []);

    const handleError = useCallback((error: string) => {
        services.navigatorService.showFinalPage('error', {
            message: error,
        });
    }, []);

    if (!initOptions) {
        return <CenterLoader loading />;
    }

    return store.selectedMethod.isInstrument ? (
        <PayrailsSavedCardView
            initOptions={initOptions}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            panValue={
                services.viewService.formatPayrailsMethodName(
                    store.selectedMethod.methodName,
                )
            }
            cvvOnly={store.selectedMethod.cvvForSavedCards}
            formFields={store.selectedMethod.formFields}
            logsService={services.logsService}
            configurationService={services.configurationService}
            onSubmit={handleSubmit}
            onError={handleError}
        />
    ) : (
        <PayrailsBankCardView
            initOptions={initOptions}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={store.selectedMethod.formFields}
            logsService={services.logsService}
            configurationService={services.configurationService}
            onSubmit={handleSubmit}
            onError={handleError}
        />
    );
};

export default PayrailsBankCardController;

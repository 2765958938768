import { WarningTrOutline } from '@indriver/mireska';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { InformationScreen } from '@/features/information-screen';
import DisplayedError from '@/lib/errors/displayed-error';
import PaymentProviderError from '@/lib/errors/payment-provider-error';
import NavigatorService from '@/services/navigator-service';

type ProviderErrorPageProps = {
    navigatorService: NavigatorService;
}

const ProviderErrorPage: FC<ProviderErrorPageProps> = ({
    navigatorService
}) => {
    const { t } = useTranslation();

    const ppError = new PaymentProviderError();
    let displayedError = new DisplayedError(ppError.providerError());
    const query = navigatorService.getQuery();
    if (query.code) {
        displayedError = DisplayedError.parse(query);
    }

    useEffect(() => {
        window.access('providererrorpage');
    }, []);

    const handleClick = useCallback(() => {
        navigatorService.goHome();
    }, []);

    return (
        <InformationScreen
            type='warning'
            heading={displayedError.title}
            description={
                displayedError.messages.map(message => (
                    <div key={message}>
                        {message}
                    </div>
                ))
            }
            errorStatus={displayedError.errorStatus}
            icon={WarningTrOutline}
            buttonText={t('pages.providerErrorPage.submitCaption')}
            onClickButton={handleClick}
        />
    );
};

export default ProviderErrorPage;

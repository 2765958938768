import { FC, forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreditCardForm } from '@/lib/forms/actions';
import { ValidationError } from '@/lib/forms/validation';
import NuveiService, { NuveiFieldType } from '@/services/nuvei-service';
import CardHolder from '@/shared/ui/domain/credit-card-field/card-holder';
import CreditCardOutline, { CreditCardViewType } from '@/shared/ui/domain/credit-card-outline';
import CenterLoader from '@/shared/ui/layout/center-loader';

export type NuveiCardData = {
    cardToken: string;
    cardHolder: string;
}

type NuveiCardProps = {
    ref: Ref<CreditCardForm<NuveiCardData>>;
    nuveiService: NuveiService;
}

const fieldIds = [NuveiFieldType.ccNumber, NuveiFieldType.ccExpiration, NuveiFieldType.ccCvc];

const NuveiCard: FC<NuveiCardProps> = forwardRef(({
    nuveiService
}, ref) => {
    const { t } = useTranslation();
    const [ready, setReady] = useState(false);
    
    const [cardNumberError, setCardNumberError] = useState<ValidationError>();
    const [expirationError, setExpirationError] = useState<ValidationError>();
    const [cvvError, setCvvError] = useState<ValidationError>();

    const [cardHolder, setCardHolder] = useState('');
    const [cardHolderError, setCardHolderError] = useState<ValidationError>();
    const cardholderRef = useRef<CardHolder>(null);

    const setErrorByField = (fieldName: NuveiFieldType, isError: boolean) => {
        switch (fieldName) {
            case NuveiFieldType.ccNumber:
                setCardNumberError(isError ? { message: t('lib.validation.cardNumber') } : undefined);
                break;
            case NuveiFieldType.ccExpiration:
                setExpirationError(isError ? { message: t('lib.validation.expirationDate') } : undefined);
                break;
            case NuveiFieldType.ccCvc:
                setCvvError(isError ? { message: t('lib.validation.cvv') } : undefined);
                break;
            default: {
                return;
            }
        }
    };

    useEffect(() => {
        nuveiService.mountFields(fieldIds);
        nuveiService.addEventListener('onReady', () => {
            setReady(true);
        });
        nuveiService.on('change', (event) => {
            setErrorByField(event.field, false);
        });
        nuveiService.on('blur', (event) => {
            setErrorByField(event.field, !event.complete);
        });
    }, []);

    useImperativeHandle(ref, () => ({
        getCvvOnly: async () => {
            return null;
        },
        validate: (): boolean => {
            return true;
        },
        setCardholderError: () => {
        },
        getData: async (extra) => {
            if (cardHolder.trim().length === 0) {
                cardholderRef.current?.validate();

                return null;
            }

            if (cardNumberError || expirationError || cvvError || cardHolderError) {
                return null;
            }

            if (!extra?.email) {
                return null;
            }

            const token = await nuveiService.getCcNumberToken({
                cardHolderName: cardHolder,
                email: extra.email
            });

            const errorMessage = token?.error?.message;
            if (errorMessage) {
                const cardNumberRegex = /card_number/i;
                if (cardNumberRegex.test(errorMessage)) {
                    setErrorByField(NuveiFieldType.ccNumber, true);
                }

                const expirationRegex = /expiration/i;
                if (expirationRegex.test(errorMessage)) {
                    setErrorByField(NuveiFieldType.ccExpiration, true);
                }

                const cvcRegex = /(cvv|cvc)/i;
                if (cvcRegex.test(errorMessage)) {
                    setErrorByField(NuveiFieldType.ccCvc, true);
                }

                const cardholderRegex = /cardholder/i;
                if (cardholderRegex.test(errorMessage)) {
                    setCardHolderError({ message: errorMessage });
                }

                return null;
            }

            if (!token?.ccTempToken) {
                return null;
            }

            return {
                cardToken: token.ccTempToken,
                cardHolder
            };
        }
    }), [cardHolder, cardholderRef]);

    return (
        <CenterLoader
            overlay
            loading={!ready}
            title={t('features.CreditCard.loadingTitle')}
        >
            <CreditCardOutline
                cardNumberInput={
                    <div
                        id={NuveiFieldType.ccNumber}
                    />

                }
                cardNumberError={cardNumberError?.message}
                expirationInput={
                    {
                        type: CreditCardViewType.COMMON,
                        children: (
                            <div
                                id={NuveiFieldType.ccExpiration}
                            />
                        ),
                        expirationError: expirationError?.message
                    }
                }
                cvvInput={
                    <div
                        id={NuveiFieldType.ccCvc}
                    />
                }
                cvvError={cvvError?.message}
                cardHolderInput={
                    <CardHolder
                        ref={cardholderRef}
                        onError={setCardHolderError}
                        onChange={
                            (value) => {
                                setCardHolder(value);
                                setCardHolderError(undefined);
                            }
                        }
                    />
                }
                cardHolderError={cardHolderError?.message}
            />
        </CenterLoader>
    );
});

export default NuveiCard;
import { FC, forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreditCardForm } from '@/lib/forms/actions';
import { ValidationError } from '@/lib/forms/validation';
import { PaymentProviderName } from '@/lib/payments/providers';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import { CardIcon } from '@/shared/ui/domain/credit-card-field/card-number/ui';
import { getCardIcon } from '@/shared/ui/domain/credit-card-field/lib/icons';
import CenterLoader from '@/shared/ui/layout/center-loader';

import * as UI from './ui';

import { PayrailsInitOptions } from '../../../graphql/payrails';
import SDK from '../../../lib/payments/sdk';
import CreditCardOutline, { CreditCardViewType } from '../../../shared/ui/domain/credit-card-outline';

type PayrailsContainer = any;

const PAYRAILS_CARD_NUMBER_ID = 'payrails-cardNumber-id';
const PAYRAILS_EXP_MONTH_ID = 'payrails-expMonth-id';
const PAYRAILS_EXP_YEAR_ID = 'payrails-expYear-id';
const PAYRAILS_CVV_ID = 'payrails-cvv-id';
const PAYRAILS_CARDHOLDER_ID = 'payrails-cardholder-id';

export type PayrailsCardData = {
    vaultedToken: string;
    numberToken: string;
    securityCodeToken: string;
} | {
    encryptedData: string;
    vaultProviderConfigId: string;
}

type PayrailsCardProps = {
    initOptions: PayrailsInitOptions;
    logsService: LogsService;
    cvvOnly?: boolean;
    configurationService: ConfigurationService;
    ref: Ref<CreditCardForm<PayrailsCardData>>;
    onError: (error: string) => void;
}

enum TEMP_FIELDS {
    // TODO удалить enum после переезда https://indriver.atlassian.net/browse/PRC-2717
    TEMP_CARDHOLDER_FIELD = 'TEMP_CARDHOLDER_FIELD',
    INPUT_FIELD = 'INPUT_FIELD',
    CARDHOLDER_NAME = 'CARDHOLDER_NAME',
}

/**
 * Docs
 * @link {https://docs.payrails.com/docs/secure-fields}
 */
const PayrailsCard: FC<PayrailsCardProps> = forwardRef(({
    initOptions,
    logsService,
    cvvOnly,
    configurationService,
    onError
}, ref) => {
    const { t } = useTranslation();
    const [payrailsContainer, setPayrailsContainer] = useState<PayrailsContainer>();

    const [cardNumberError, setCardNumberError] = useState<ValidationError>();
    const [expirationMonthError, setExpirationMonthError] = useState<ValidationError>();
    const [expirationYearError, setExpirationYearError] = useState<ValidationError>();
    const [cvvError, setCvvError] = useState<ValidationError>();
    const [cardHolderError, setCardHolderError] = useState<ValidationError>();

    const [cardType, setCardType] = useState('');
    const [show, setShow] = useState(false);

    const isFieldsValidRef = useRef<Record<string, boolean>>({
        CARD_NUMBER: false,
        EXPIRATION_MONTH: false,
        EXPIRATION_YEAR: false,
        CVV: false,
        // INPUT_FIELD: false,
        // CARDHOLDER_NAME: false
        // TODO удалить после переезда https://indriver.atlassian.net/browse/PRC-2717
        [TEMP_FIELDS.TEMP_CARDHOLDER_FIELD]: false,
    });

    useEffect(() => {
        const sdk = new SDK(logsService);
        sdk.get(PaymentProviderName.Payrails)
            .then(({ ElementType, Payrails }) => {
                const handleChange = (
                    e: { elementType: typeof ElementType, network?: string, isValid: boolean }
                ) => {
                    // TODO удалить после переезда https://indriver.atlassian.net/browse/PRC-2717
                    const rawType = e.elementType;
                    const type = (rawType === TEMP_FIELDS.INPUT_FIELD || rawType === TEMP_FIELDS.CARDHOLDER_NAME)
                        ? TEMP_FIELDS.TEMP_CARDHOLDER_FIELD : e.elementType;

                    isFieldsValidRef.current[type] = e.isValid;

                    if (e.elementType === ElementType.CARD_NUMBER) {
                        setCardNumberError(undefined);
                        setCardType(e.network || '');

                        return;
                    }

                    if (e.elementType === ElementType.EXPIRATION_MONTH) {
                        setExpirationMonthError(undefined);

                        return;
                    }

                    if (e.elementType === ElementType.EXPIRATION_YEAR) {
                        setExpirationYearError(undefined);

                        return;
                    }

                    if (e.elementType === ElementType.CVV) {
                        setCvvError(undefined);

                        return;
                    }

                    // TODO переписать после переезда https://indriver.atlassian.net/browse/PRC-2717
                    if (type === TEMP_FIELDS.TEMP_CARDHOLDER_FIELD) {
                        setCardHolderError(undefined);

                        return;
                    }
                };

                const handleBlur = (
                    e: { elementType: typeof ElementType, isValid: boolean }
                ) => {
                    // TODO удалить после переезда https://indriver.atlassian.net/browse/PRC-2717
                    const rawType = e.elementType;
                    const type = (rawType === TEMP_FIELDS.INPUT_FIELD || rawType === TEMP_FIELDS.CARDHOLDER_NAME)
                        ? TEMP_FIELDS.TEMP_CARDHOLDER_FIELD : e.elementType;

                    isFieldsValidRef.current[type] = e.isValid;

                    if (e.elementType === ElementType.CARD_NUMBER) {
                        if (!e.isValid) {
                            setCardNumberError({
                                message: t('lib.validation.cardNumber')
                            });
                        }

                        return;
                    }

                    if (e.elementType === ElementType.EXPIRATION_MONTH) {
                        if (!e.isValid) {
                            setExpirationMonthError({
                                message: t('lib.validation.expirationMonth')
                            });
                        }

                        return;
                    }

                    if (e.elementType === ElementType.EXPIRATION_YEAR) {
                        if (!e.isValid) {
                            setExpirationYearError({
                                message: t('lib.validation.expirationYear')
                            });
                        }

                        return;
                    }

                    if (e.elementType === ElementType.CVV) {
                        if (!e.isValid) {
                            setCvvError({
                                message: t('lib.validation.cvv')
                            });
                        }

                        return;
                    }

                    // TODO переписать после переезда https://indriver.atlassian.net/browse/PRC-2717
                    if (type === TEMP_FIELDS.TEMP_CARDHOLDER_FIELD) {
                        if (!e.isValid) {
                            setCardHolderError({
                                message: t('lib.validation.cardHolder')
                            });
                        }

                        return;
                    }
                };

                const payrailsClient = Payrails.init(initOptions, {
                    environment: configurationService.isProd() ? 'PRODUCTION' : 'TEST'
                });

                const container = payrailsClient.collectContainer({
                    containerType: 'COLLECT'
                });

                const fieldsToBeReady = {
                    [ElementType.CARD_NUMBER]: false,
                    [ElementType.EXPIRATION_MONTH]: false,
                    [ElementType.EXPIRATION_YEAR]: false,
                    [ElementType.CVV]: false,
                    [ElementType.CARDHOLDER_NAME]: false
                };

                const fields = cvvOnly ? {
                    [PAYRAILS_CVV_ID]: ElementType.CVV,
                } : {
                    [PAYRAILS_CARD_NUMBER_ID]: ElementType.CARD_NUMBER,
                    [PAYRAILS_EXP_MONTH_ID]: ElementType.EXPIRATION_MONTH,
                    [PAYRAILS_EXP_YEAR_ID]: ElementType.EXPIRATION_YEAR,
                    [PAYRAILS_CVV_ID]: ElementType.CVV,
                    [PAYRAILS_CARDHOLDER_ID]: ElementType.CARDHOLDER_NAME
                };

                // https://docs.payrails.com/docs/elements
                Object.entries(fields).forEach(([id, type]) => {
                    const field = container.createCollectElement({
                        type,
                        required: true,
                        enableCardIcon: false,
                        inputStyles: UI.getSdkInputStyles()
                    });

                    if (field.mount) {
                        field.on('CHANGE', handleChange);
                        field.on('BLUR', handleBlur);
                        field.on('READY', () => {
                            fieldsToBeReady[ElementType[type]] = true;

                            const isAllFieldsReady = Object.values(fieldsToBeReady).every(ready => ready);
                            if (isAllFieldsReady) {
                                setShow(true);
                            }
                        });

                        field.mount(`#${id}`);
                    }
                });

                setPayrailsContainer(container);
            })
            .catch(e => {
                onError(e);
            });
    }, []);

    useImperativeHandle(ref, () => ({
        validate: (): boolean => {
            return true;
        },
        setCardholderError: (error) => {
            if (error?.message) {
                setCardHolderError(error);
            }
        },
        getData: async (): Promise<PayrailsCardData|null> => {
            if (!payrailsContainer) {
                return null;
            }

            const isAllFieldsValid = cvvOnly ?
                Boolean(isFieldsValidRef.current.CVV) :
                isFieldsValidRef.current.CARD_NUMBER &&
                isFieldsValidRef.current.EXPIRATION_MONTH &&
                isFieldsValidRef.current.EXPIRATION_YEAR &&
                isFieldsValidRef.current.CVV &&
                isFieldsValidRef.current.TEMP_CARDHOLDER_FIELD;

            // TODO после переезда https://indriver.atlassian.net/browse/PRC-2717 заменить на
            // const isAllFieldsValid = Object.values(isFieldsValidRef.current).every(Boolean)

            if (!isAllFieldsValid) {
                if (!isFieldsValidRef.current.CARD_NUMBER) {
                    setCardNumberError({
                        message: t('lib.validation.cardNumber')
                    });
                }
                if (!isFieldsValidRef.current.EXPIRATION_MONTH) {
                    setExpirationMonthError({
                        message: t('lib.validation.expirationMonth')
                    });
                }
                if (!isFieldsValidRef.current.EXPIRATION_YEAR) {
                    setExpirationYearError({
                        message: t('lib.validation.expirationYear')
                    });
                }
                if (!isFieldsValidRef.current.CVV) {
                    setCvvError({
                        message: t('lib.validation.cvv')
                    });
                }
                if (!isFieldsValidRef.current.TEMP_CARDHOLDER_FIELD) {
                    setCardHolderError({
                        message: t('lib.validation.cardHolder')
                    });
                }

                return null;
            }

            try {
                const data = await payrailsContainer.collect();
                const { instrumentId, card_number, security_code, encryptedData, vaultProviderConfigId } = data;
                const oldFormat = Boolean(instrumentId) && Boolean(card_number) && Boolean(security_code);
                const newFormat = Boolean(encryptedData) && Boolean(vaultProviderConfigId);

                if (!oldFormat && !newFormat) {
                    return null;
                }

                if (oldFormat) {
                    return {
                        vaultedToken: instrumentId,
                        numberToken: card_number,
                        securityCodeToken: security_code
                    };
                }

                return { encryptedData, vaultProviderConfigId };
            } catch (e) {
                // SkyFlow error
                onError(t('lib.errors.defaultMessage'));
            }

            return null;
        }
    }), [payrailsContainer]);

    if (cvvOnly) {
        return (
            <>
                <CreditCardOutline
                    cvvOnly
                    cvvInput={
                        <UI.PayrailsInput id={PAYRAILS_CVV_ID} />
                    }
                    cvvError={cvvError?.message}
                />
            </>
        );
    }

    return (
        <CenterLoader
            title={t('features.CreditCard.loadingTitle')}
            overlay
            loading={!show}
        >
            <CreditCardOutline
                cardNumberInput={
                    <UI.Wrapper>
                        <UI.PayrailsInput id={PAYRAILS_CARD_NUMBER_ID} />
                        <CardIcon>{getCardIcon(cardType)}</CardIcon>
                    </UI.Wrapper>
                }
                cardNumberError={cardNumberError?.message}
                expirationInput={
                    {
                        type: CreditCardViewType.SEPARATE,
                        expirationMonth: <UI.PayrailsMonthInput id={PAYRAILS_EXP_MONTH_ID} />,
                        expirationMonthError: expirationMonthError?.message,
                        expirationYear: <UI.PayrailsYearInput id={PAYRAILS_EXP_YEAR_ID} />,
                        expirationYearError: expirationYearError?.message
                    }
                }
                cvvInput={
                    <UI.PayrailsInput id={PAYRAILS_CVV_ID} />
                }
                cardHolderInput={
                    <UI.PayrailsInput id={PAYRAILS_CARDHOLDER_ID} />
                }
                cardHolderError={cardHolderError?.message}
                cvvError={cvvError?.message}
            />
        </CenterLoader>
    );
});

export default PayrailsCard;

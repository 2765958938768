import SVGProps from '@indriver/mireska/dist/types/types';
import { variables } from '@indriver/yrel';
import IMask, { InputMask } from 'imask';
import { ComponentPropsWithoutRef, FC, useEffect, useRef, useState } from 'react';

import { FormField } from '@/lib/payments/forms';

import InputOutline from '../input-outline';

import * as UI from './ui';

type FormInputProps = {
    'data-id'?: FormField['id'],
    title: string;
    mask?: string | typeof Number | typeof String;
    error?: string;
    children?: JSX.Element;
    icon?: (props: SVGProps) => JSX.Element;
    /** Hides icon if screen too small */
    short?: boolean;
    onIconClick?: VoidFunction;
    onMaskSet?: (id: FormField['id'], mask: InputMask<{ mask: any }>) => void;
} & Pick<ComponentPropsWithoutRef<'input'>,
    | 'value'
    | 'type'
    | 'disabled'
    | 'onBlur'
    | 'onChange'
>;

const FormInput: FC<FormInputProps> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [iMask, setIMask] = useState<InputMask<{ mask: any }>|null>(null);

    useEffect(() => {
        if (!props.mask || !inputRef.current) {
            return;
        }

        // @ts-ignore
        const inputMask = IMask(inputRef.current, {
            mask: props.mask,
        });

        setIMask(inputMask);
        if (props.onMaskSet && props['data-id']) {
            props.onMaskSet(props['data-id'], inputMask);
        }
        // better handling of controlled input
        // we should sync props.onChange with 'accept' event of mask
        inputMask.on('accept', (e: never) => {
            if (props.onChange) {
                props.onChange(e);
            }
        });
    }, [inputRef]);

    const inputProps = {
        type: props.type,
        ['data-id']: props['data-id'],
        pattern: props.type === 'number' ? '\\d*' : '',
        value: !iMask ? props.value : iMask.value,
        disabled: props.disabled,
        onBlur: props.onBlur,
        // we dont pass onChange to input if mask enabled
        onChange: !iMask ? props.onChange : undefined
    };

    const inputChildren = props.children ||
        <UI.Input
            ref={inputRef}
            {...inputProps}
        />;

    const inputContent = props.icon ? (
        <UI.WithIcon $hidable={props.short}>
            {inputChildren}
        </UI.WithIcon>
    ) : inputChildren;

    const IconComponent = props.icon;

    return (
        <InputOutline
            id={props['data-id']}
            title={props.title}
            error={props.error}
        >
            <>
                {inputContent}

                {
                    IconComponent ? (
                        <UI.Icon
                            $hidable={props.short}
                            data-id={`${props['data-id']}-icon`}
                            onClick={props.onIconClick}
                        >
                            <IconComponent
                                size={24}
                                color={variables['text-and-icon-secondary']}
                            />
                        </UI.Icon>
                    ) : null
                }
            </>
        </InputOutline>
    );
};

export default FormInput;

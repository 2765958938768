import i18n from 'i18next';

import {
    getAmountField,
    getConfirmationCodeField,
} from './fields';
import {
    BILLING_ADDRESS_SECTION_ID,
    PERSONALDETAILS_SECTION_ID,
    AMOUNT_SECTION_ID, HOME_ADDRESS_SECTION_ID
} from './indentificators';
import { FormField, FormSection } from './index';

export const getAmountSection = (): FormSection => ({
    id: AMOUNT_SECTION_ID,
    title: i18n.t('services.formBuilder.sections.amountTitle'),
    fields: [getAmountField()],
});

export const getPersonalDetailsSection = (fields: FormField[]): FormSection => ({
    id: PERSONALDETAILS_SECTION_ID,
    title: i18n.t('services.formBuilder.sections.personalDetailTitle'),
    fields: fields
});

export const getBillingAddressSection = (fields: FormField[]): FormSection => ({
    id: BILLING_ADDRESS_SECTION_ID,
    title: i18n.t('services.formBuilder.sections.billingTitle'),
    fields: fields
});

export const getHomeAddressSection = (fields: FormField[]): FormSection => ({
    id: HOME_ADDRESS_SECTION_ID,
    title: i18n.t('services.formBuilder.sections.homeAddress'),
    fields: fields
});

export const getCodeConfirmationSections =  () => ([
    getPersonalDetailsSection([
        getConfirmationCodeField(),
    ])
]);

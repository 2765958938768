import DisplayedError from '@/lib/errors/displayed-error';
import JWTToken from '@/lib/jwt';
import { Countries } from '@/lib/locale/countries';
import { FormSection } from '@/lib/payments/forms';
import {
    DOCUMENT_ID,
    EMAIL_ID,
    FULL_ADDRESS_ID,
    FULL_BILLING_ADDRESS_ID,
    PHONE_ID,
} from '@/lib/payments/forms/indentificators';
import { PaymentType } from '@/lib/payments/payments';
import Providers, { PaymentProviderName } from '@/lib/payments/providers';
import EventEmitter from '@/lib/subscriber';
import FormFieldsService from '@/services/form-fields-service';
import IPCService from '@/services/ipc-service';
import PaymentProviderService from '@/services/payment-provider-service';
import {
    PaymentInfoData,
    PaymentMethodData
} from '@/services/payment-provider-service/api/init';

export type YunoSession = {
    sessionToken: string;
    apiKey: string
    vaultedToken: string;
    methodType: string;
    methodName: string;
}

export enum MapLink {
    REDIRECT,
    FRAME
}

export type PaymentMethod = {
    available: boolean;
    methodName: string;
    paymentType: PaymentType;
    providerName: PaymentProviderName;
    isCustomerSaved: boolean;
    cvvForSavedCards: boolean;
    isInstrument: boolean;
    isRemovable: boolean;
    paymentMethodCode?: string;
    limits: {
        min: number;
        max: number;
    },
    session?: {
        yuno?: YunoSession;
        gifty?: {
            referenceNumber: string;
        };
        dlocal?: {
            apiKey: string;
        };
        credorax?: {
            cardId?: string;
            staticKey: string;
            merchantID: string;
        };
        imepay?: {
            redirectUrl?: string;
            referenceNumber: string;
        };
        payrails?: {
            instrumentId: string;
            paymentId: string;
            session: string;
        };
        puntoxpress?: {
            referenceNumber: string;
        };
        qiwi_kz?: {
            referenceNumber: string;
        };
        kassa24?: {
            referenceNumber: string;
        };
        kaspi?: {
            referenceNumber: string;
            redirectUrl: string;
        };
        fawry?: {
            referenceNumber: string;
            paymentExpiry: number;
        };
        izi?: {
            voucherReference: string;
        }
        onoi?: {
            referenceNumber: string;
        }
    };
    savedInstruments?: SavedInstrument;
    formFields: FormSection[];
    hidableFormFields?: {
        label: string;
        fields: FormSection[];
    };
    isFormless: boolean;
    support: {
        phones: string[];
        emails: string[];
        map?: {
            link: MapLink;
            src: string;
        };
    }
}

export type SavedInstrument = {
    bankCard?: SavedInstrumentBankCard[];
}

export type SavedInstrumentBankCard = {
    id: string;
    last4: string;
}

export type SavedAutoTopup = {
    enable: boolean;
    amount: number;
    balance_threshold: number;
    card: {
        uuid?: string;
        token: string;
        last4?: string;
    };
}

export type AutoTopupInfo = {
    visible: true;
    enable: boolean;
    thresholdSuggests: number[];
    notSettedUp: boolean;
    amountSuggests: number[];
    userSettings: {
        enabled: boolean;
        balanceThreshold: number;
        amount: number;
        savedCard: {
            uuid: string;
            last4: string;
            token: string;
        };
    }
} | {
    visible: false;
}

export type AutoTopupInfoDisabled = false

export type PaymentInfo = {
    country: Countries;
    paymentMethods: PaymentMethod[];
    suggestedAmounts: number[];
    defaultMethod: number | null;
    savedDocuments: {
        [EMAIL_ID]: string;
        [DOCUMENT_ID]: string;
        [PHONE_ID]: string;
        [FULL_ADDRESS_ID]: string;
        [FULL_BILLING_ADDRESS_ID]: string;
    },
    autoTopup: AutoTopupInfo;
    defaultAmount: string;
}

class PaymentInfoService extends EventEmitter<{
    'onPaymentInfoUpdate': PaymentInfo,
    'onError': DisplayedError;
}> {
    private paymentInfo: PaymentInfo | null;
    private paymentProviderService: PaymentProviderService;
    private ipcService: IPCService;
    private formFieldService: FormFieldsService;

    constructor(
        paymentProviderService: PaymentProviderService,
        ipcService: IPCService,
    ) {
        super();

        this.paymentInfo = null;
        this.paymentProviderService = paymentProviderService;
        this.ipcService = ipcService;
        this.formFieldService = new FormFieldsService();
    }

    private hideMethod = (
        selectedMethod: PaymentMethod,
        methods: PaymentMethod[]
    ): PaymentMethod[] => {
        return methods.map(method => {
            if (method === selectedMethod) {
                return {
                    ...method,
                    available: false,
                };
            }

            return method;
        });
    };

    // todo https://indriver.atlassian.net/browse/PRC-2052
    // todo двигаем установку дефолтных методов на бек
    private getDefaultMethod = async (
        country: Countries,
        methods: PaymentMethod[]
    ) => {
        const selectedProvider = await this.ipcService.getSelectedProviderPayload();
        if (Providers.isPaymentProviderName(selectedProvider)) {
            return this.getIndexOf(methods, selectedProvider);
        }

        switch (country) {
            case Countries.Kazakhstan:
                return this.getIndexOf(methods, PaymentProviderName.KaspiKz);
        }

        return null;
    };

    private getIndexOf = (
        methods: PaymentMethod[],
        providerName: PaymentProviderName,
    ): number | null => {
        if (!methods.length) {
            return null;
        }

        if (methods.length === 1) {
            return 0;
        }

        for (let i = 0; i < methods.length; i++) {
            if (methods[i].providerName === providerName) {
                return i;
            }
        }

        return null;
    };

    private getSupportPhones = (country: Countries, providerName: PaymentProviderName) => {
        if (providerName === PaymentProviderName.Puntoxpress) {
            switch (country) {
                case Countries.Nicaragua:
                    return ['75167893'];
                case Countries.Honduras:
                    return ['(+504) 2280-8646'];
            }
        }

        return [];
    };

    private getSupportEmails = (country: Countries, providerName: PaymentProviderName) => {
        if (providerName === PaymentProviderName.Puntoxpress) {
            switch (country) {
                case Countries.Salvador:
                    return ['soporte-sv@puntoxpress.com'];
                case Countries.Nicaragua:
                    return ['soporte-ni@puntoxpress.com'];
                case Countries.Guatemala:
                    return ['servicioalcliente@akisi.com'];
                case Countries.Honduras:
                    return ['servicioalcliente@tengo.hn'];
            }
        }

        return [];
    };

    private getMapLink = (country: Countries, providerName: PaymentProviderName) => {
        if (providerName === PaymentProviderName.Fawry) {
            const map = {
                link: MapLink.FRAME,
                src: ''
            };

            switch (country) {
                case Countries.Egypt:
                    // eslint-disable-next-line max-len
                    map.src = 'https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d884584.9286779079!2d30.9832197!3d29.9963851!3m2!1i1024!2i768!4f13.1!2m1!1sfawry!5e0!3m2!1sen!2s!4v1688134429892!5m2!1sen!2s';
                    break;
                default:
                    // eslint-disable-next-line max-len
                    map.src = 'https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1774752.2077379352!2d31.383808372678853!3d29.68170651863243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sFawry!5e0!3m2!1sen!2s!4v1688134504525!5m2!1sen!2s';
            }

            return map;
        }

        if (providerName === PaymentProviderName.Puntoxpress) {
            const map = {
                link: MapLink.FRAME,
                src: ''
            };

            switch (country) {
                case Countries.Salvador:
                    map.src = 'https://www.google.com/maps/d/embed?mid=1YUFn1nYGpeY-vlFsybNTX3XxcWbZRt-g&ehbc=2E312F';
                    break;
                case Countries.Nicaragua:
                    // eslint-disable-next-line max-len
                    map.src = 'https://www.google.com/maps/d/embed?mid=1wN6rVk9tvrBKB0CVW9TRqBD_oZlnlHg&ehbc=2E312F';
                    break;
                case Countries.Guatemala:
                    // eslint-disable-next-line max-len
                    map.src = 'https://www.google.com/maps/d/embed?mid=1-fdEW7_-nHGtcqOzIMlfuD9bapkk0kDe&ehbc=2E312F';
                    break;
                case Countries.Honduras:
                    // eslint-disable-next-line max-len
                    map.src = 'https://www.google.com/maps/d/embed?mid=1mDFRSqIiH5LhAF4nqfkk8moQo9InhuE&ehbc=2E312F';
                    break;
                default:
                    return undefined;
            }

            return map;
        }

        if (providerName === PaymentProviderName.Gifty) {
            return {
                link: MapLink.REDIRECT,
                src: 'https://linktr.ee/indrive.stores'
            };
        }

        return undefined;
    };

    private isCvvForSavedCards = (country: Countries, providerName: PaymentProviderName) => {
        switch (providerName) {
            case PaymentProviderName.Dlocal:
                return country === Countries.India;
            case PaymentProviderName.Payrails:
                return country === Countries.India;
        }

        return false;
    };

    private getSession = (method: PaymentMethod, item: PaymentMethodData) => {
        let session: PaymentMethod['session'];

        if (method.providerName === PaymentProviderName.Yuno && item.session?.yuno) {
            session = {
                yuno: {
                    apiKey: item.session.yuno.apiKey,
                    sessionToken: item.session.yuno.session,
                    vaultedToken: item.session.yuno.token,
                    methodType: method.paymentMethodCode || '',
                    methodName: method.paymentType,
                }
            };

            method.isRemovable = item.is_removable;
            method.isInstrument = item.is_removable;
        }

        if (method.providerName === PaymentProviderName.Gifty && item.session?.gifty) {
            session = {
                gifty: {
                    referenceNumber: item.session.gifty.reference_number,
                }
            };
        }

        if (method.providerName === PaymentProviderName.Credorax && item.session?.credorax) {
            session = {
                credorax: {
                    staticKey: item.session.credorax.staticKey,
                    merchantID: item.session.credorax.merchantID,
                },
            };
        }

        if (method.providerName === PaymentProviderName.ImePay && item.session?.imepay) {
            session = {
                imepay: {
                    referenceNumber: item.session.imepay.reference_number,
                    redirectUrl: item.session.imepay.redirect_url,
                }
            };
        }

        if (method.providerName === PaymentProviderName.Payrails && item.session?.payrails) {
            session = {
                payrails: {
                    instrumentId: item.session.payrails.instrument_id,
                    paymentId: item.session.payrails.payment_id,
                    session: item.session.payrails.session,
                }
            };
            if (item.session.payrails.instrument_id) {
                method.isInstrument = true;
            }
        }

        if (method.providerName === PaymentProviderName.Dlocal && item.session?.dlocal) {
            session = {
                dlocal: {
                    apiKey: item.session.dlocal.api_key,
                }
            };
        }

        if (method.providerName === PaymentProviderName.Puntoxpress && item.session?.puntoxpress) {
            session = {
                puntoxpress: {
                    referenceNumber: item.session.puntoxpress.reference_number
                }
            };
        }

        if (method.providerName === PaymentProviderName.QiwiKz && item.session?.qiwi_kz) {
            session = {
                qiwi_kz: {
                    referenceNumber: item.session.qiwi_kz.reference_number
                }
            };
        }

        if (method.providerName === PaymentProviderName.Kassa24 && item.session?.kassa24) {
            session = {
                kassa24: {
                    referenceNumber: item.session.kassa24.reference_number
                }
            };
        }

        if (method.providerName === PaymentProviderName.Onoi && item.session?.onoi) {
            session = {
                onoi: {
                    referenceNumber: item.session.onoi.reference_number
                }
            };
        }

        if (method.providerName === PaymentProviderName.KaspiKz && item.session?.kaspi) {
            const KASPI_SERVICE_ID = 3027;
            const KASPI_PARAMETER_ID = 4169;
            const referenceNumber = item.session.kaspi.reference_number;

            const params = new URLSearchParams();
            params.append('service_id', KASPI_SERVICE_ID.toString());
            params.append('browser', 'true');
            params.append(KASPI_PARAMETER_ID.toString(), referenceNumber);

            const redirectUrl = `https://kaspi.kz/pay/InDriverDirect?${params.toString()}`;

            session = {
                kaspi: {
                    referenceNumber,
                    redirectUrl,
                }
            };
        }

        return session;
    };

    private adaptPaymentInfo = (rawData: PaymentInfoData): PaymentInfo => {
        const autoTopup: AutoTopupInfo = rawData.auto_topup ? {
            visible: true,
            enable: rawData.auto_topup.enable,
            amountSuggests: rawData.auto_topup.amount_suggests,
            thresholdSuggests: rawData.auto_topup.threshold_suggests,
            notSettedUp: /** Not setted up means that user never interacted with autotopups before */
                rawData.auto_topup.user_settings.balance_threshold === 0 &&
                rawData.auto_topup.user_settings.amount === 0,
            userSettings: {
                enabled: rawData.auto_topup.user_settings.enabled,
                balanceThreshold: rawData.auto_topup.user_settings.balance_threshold,
                amount: rawData.auto_topup.user_settings.amount,
                savedCard: {
                    uuid: rawData.auto_topup.user_settings.saved_card.uuid,
                    last4: rawData.auto_topup.user_settings.saved_card.last4,
                    token: rawData.auto_topup.user_settings.saved_card.token
                }
            }
        } : {
            visible: false,
        };

        const country = rawData.country_id;

        const paymentMethods = rawData?.payment_methods?.map(item => {
            const methodName = item.method_name || item.payment_type;
            const paymentType = item.payment_type as PaymentType;
            const providerName = item.provider as PaymentProviderName;

            const data: PaymentMethod = {
                available: true,
                isInstrument: false,
                methodName,
                paymentType,
                providerName,
                isCustomerSaved: item.is_customer_saved,
                isRemovable: item.is_removable,
                limits: {
                    min: item.limits.min,
                    max: item.limits.max,
                },
                support: {
                    phones: this.getSupportPhones(country, providerName),
                    emails: this.getSupportEmails(country, providerName),
                    map: this.getMapLink(country, providerName),
                },
                formFields: [],
                isFormless: false,
                cvvForSavedCards: this.isCvvForSavedCards(country, providerName),
            };

            if (item.provider_method_code) {
                // TODO https://indriver.atlassian.net/browse/PRC-2356
                data.paymentMethodCode = item.provider_method_code;
            }

            if (item.saved_instruments?.bank_card) {
                data.savedInstruments = {
                    bankCard: item.saved_instruments.bank_card.map(card => ({
                        id: card.bank_card_id,
                        last4: card.last4,
                    }))
                };

                // TODO https://indriver.atlassian.net/browse/PRC-2208
                data.isInstrument = true;
                data.isRemovable = true;
            }

            const formFields = this.formFieldService.getFormFields(data, country);
            data.formFields = formFields;
            data.hidableFormFields = this.formFieldService.getHidableFormFields(data);
            data.isFormless = this.formFieldService.isFormless(data.providerName, formFields);

            const session = this.getSession(data, item);
            if (session) {
                data.session = session;
            }

            return data;
        });

        return {
            country,
            suggestedAmounts: rawData.suggested_amounts || [],
            defaultMethod: null,
            paymentMethods: paymentMethods || [],
            savedDocuments: {
                email: rawData.fill_form?.email || '',
                document: rawData.fill_form?.document || '',
                phone: rawData.fill_form?.phone || '',
                address: rawData.fill_form?.address || '',
                billingAddress: rawData.fill_form?.billingAddress || '',
            },
            autoTopup,
            defaultAmount: '',
        };
    };

    private async deletePaymentMethod(method: PaymentMethod) {
        if (method.providerName === PaymentProviderName.Yuno) {
            if (method.session?.yuno) {
                return this.paymentProviderService.deleteYunoPaymentMethod({
                    token: method.session.yuno.vaultedToken
                });
            }
        }

        // TODO https://indriver.atlassian.net/browse/PRC-2208
        if (method.providerName === PaymentProviderName.Nuvei ||
            method.providerName === PaymentProviderName.Dlocal ||
            method.providerName === PaymentProviderName.Credorax) {
            if (method.savedInstruments?.bankCard) {
                const card = method.savedInstruments?.bankCard[0];

                this.paymentProviderService.deleteCard({ cardId: card.id })
                    .finally(() => window.location.reload());

                return;
            }
        }

        if (method.providerName === PaymentProviderName.Payrails) {
            if (method.session?.payrails) {
                return this.paymentProviderService.deletePayrailsPaymentMethod({
                    instrumentID:  method.session.payrails.instrumentId,
                });
            }
        }

        return;
    }

    public async deleteMethod(method: PaymentMethod) {
        if (!this.paymentInfo) {
            return;
        }

        this.paymentInfo.paymentMethods = this.hideMethod(
            method,
            this.paymentInfo.paymentMethods
        );

        this.dispatch('onPaymentInfoUpdate', this.paymentInfo);

        this.deletePaymentMethod(method);
    }

    public async init(jwt: JWTToken) {
        try {
            const paymentInfoData = await this.paymentProviderService.getPaymentInfo();
            const paymentInfo = this.adaptPaymentInfo(paymentInfoData);
            const token = jwt.getToken();

            this.paymentInfo = {
                defaultMethod: await this.getDefaultMethod(
                    paymentInfo.country,
                    paymentInfo.paymentMethods
                ),
                paymentMethods: paymentInfo.paymentMethods,
                suggestedAmounts: paymentInfo.suggestedAmounts,
                country: token.country_id,
                savedDocuments: paymentInfo.savedDocuments,
                autoTopup: paymentInfo.autoTopup,
                defaultAmount: await this.ipcService.getTopupPayload(),
            };

            this.dispatch('onPaymentInfoUpdate', this.paymentInfo);
        } catch (e) {
            if (e instanceof DisplayedError) {
                this.dispatch('onError', e);
            }
        }
    }

    /** We are working only with Yuno for Auto top up */
    public getYunoSavedCards = () => {
        return this.paymentInfo?.paymentMethods.filter((method) => (
            method.session?.yuno?.vaultedToken &&
            method.paymentType === PaymentType.BANK_CARD &&
            method.available
        )) || [];
    };

    public hasYunoSavedCards = () => {
        return this.getYunoSavedCards().length > 0;
    };

    public setFawryCashData = (data: {
        referenceNumber: string
        paymentExpiry: number
    }) => {
        if (!this.paymentInfo) {
            return;
        }

        this.paymentInfo.paymentMethods = this.paymentInfo.paymentMethods.map(method => {
            if (method.providerName === PaymentProviderName.Fawry) {
                method.session = {
                    fawry: data,
                };
            }

            return method;
        });

        this.paymentInfo.defaultMethod = this.getIndexOf(
            this.paymentInfo.paymentMethods,
            PaymentProviderName.Fawry
        );

        this.dispatch('onPaymentInfoUpdate', this.paymentInfo);
    };

    public setIziCashData = (data: { voucherReference: string}) => {
        if (!this.paymentInfo) {
            return;
        }

        this.paymentInfo.paymentMethods = this.paymentInfo.paymentMethods.map(method => {
            if (method.providerName === PaymentProviderName.Izi) {
                method.session = {
                    izi: data,
                };
            }

            return method;
        });

        this.paymentInfo.defaultMethod = this.getIndexOf(
            this.paymentInfo.paymentMethods,
            PaymentProviderName.Izi
        );

        this.dispatch('onPaymentInfoUpdate', this.paymentInfo);
    };
}

export default PaymentInfoService;

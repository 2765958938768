import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';

import PaymentProviderI18N, { PaymentProviderI18NError } from '../../i18n/payment-provider';

export type GQLErrorType = GQLError | undefined;

// todo
// 1) Улучшить геттеры по типизации, сейчас сначала надо проверять на type,
// а потом делать какой-то get - получается непрозрачно
// но я рефакторил этот на соответствие типам и чтобы ничего не сломать, поэтому
// следующим этапом надо сделать лаконичнее

// 2) Обработка этой ошибки уходит глубоко в UI и торчит везде как return type
// Надо отрефакторить это тоже и не тащить так глубоко во всю программу этот кусок
// Когда я это делал впервые, я решал проблему чтобы не таскать данные туда сюда через
// пропсы во избежание боилерплейтов, но результат оказался хуже, теперь ошибка торчит
// зависимостью по всему UI, эксперимент провален

// Списки error кодов
// eslint-disable-next-line max-len
// https://github.com/inDriver/payment-provider/blob/8d626ea9ef97e0e74ae0c28d4fa16d697211216b/internal/apperr/ui_error_code.go#L9
// eslint-disable-next-line max-len
// https://github.com/inDriver/payment-provider/blob/8d626ea9ef97e0e74ae0c28d4fa16d697211216b/internal/controllers/gqlapi/resolver/resolver.go#L30
class GQLError {
    private error: {
        type: 'VALIDATION_ERROR_CODE',
        errors: { field: string; message: string; }[]
    } | {
        type:
            | 'INTERNAL_SERVER_ERROR_CODE'
            | 'LAST_NAME_IS_REQUIRED'
            | 'EMAIL_IS_NOT_VALID'
            | 'PROVIDER_ERROR_CODE_TEXT'
        message: string,
    };

    constructor(error: GQLError['error']) {
        this.error = error;
    }

    public static create(error: ApolloError) {
        const ppI18N = new PaymentProviderI18N();
        const errorData = error.graphQLErrors[0];
        if (!errorData) {
            return undefined;
        }

        const errorCode = errorData.extensions.errorCode;
        // typecast because ApolloError have no generic args
        const ppi18nErrors: PaymentProviderI18NError[]  = errorData.extensions?.errors as [] || [];

        if (errorCode === 'VALIDATION_ERROR_CODE') {
            try {
                return new GQLError({
                    type: 'VALIDATION_ERROR_CODE',
                    errors: ppi18nErrors.map((i18nError: PaymentProviderI18NError) => {
                        return {
                            field: i18nError.i18nArgs.field,
                            message: ppI18N.getErrorText(
                                i18nError, 'services.paymentProvider.errorMessage'
                            )
                        };
                    }),
                });
            } catch (e) {
                Sentry.captureException(e);
            }
        }

        if (errorCode === 'LAST_NAME_IS_REQUIRED') {
            return new GQLError({
                type: 'LAST_NAME_IS_REQUIRED',
                message: ppI18N.getErrorText(
                    ppi18nErrors[0], 'paymentProvider.providerErrorCodeText'
                ),
            });
        }

        if (errorCode === 'EMAIL_IS_NOT_VALID') {
            return new GQLError({
                type: 'EMAIL_IS_NOT_VALID',
                message: ppI18N.getErrorText(
                    ppi18nErrors[0], 'paymentProvider.providerErrorCodeText'
                ),
            });
        }

        if (errorCode === 'PROVIDER_ERROR_CODE_TEXT') {
            return new GQLError({
                type: 'PROVIDER_ERROR_CODE_TEXT',
                message: errorData.message,
            });
        }

        return new GQLError({
            type: 'INTERNAL_SERVER_ERROR_CODE',
            message: ppI18N.getErrorText(
                ppi18nErrors[0], 'paymentProvider.providerErrorCodeText'
            ),
        });
    }

    public getOnlyOneError = (): string => {
        if (this.error.type === 'VALIDATION_ERROR_CODE') {
            return this.error.errors[0]?.message || '';
        } else if (this.error.type === 'INTERNAL_SERVER_ERROR_CODE') {
            return this.error.message;
        }

        return '';
    };

    public isValidationError = () => {
        return this.error.type === 'VALIDATION_ERROR_CODE';
    };

    public getErrors = () => {
        if (this.error.type === 'VALIDATION_ERROR_CODE') {
            return this.error.errors;
        }

        return [];
    };

    public isInternalError = () => {
        return this.error.type === 'INTERNAL_SERVER_ERROR_CODE';
    };

    public isProfileError = () => {
        return this.error.type === 'LAST_NAME_IS_REQUIRED' ||
            this.error.type === 'EMAIL_IS_NOT_VALID';
    };

    public getMessage = () => {
        if (
            this.error.type === 'INTERNAL_SERVER_ERROR_CODE' ||
            this.error.type === 'LAST_NAME_IS_REQUIRED' ||
            this.error.type === 'EMAIL_IS_NOT_VALID'
        ) {
            return this.error.message;
        }

        return '';
    };

    public getErrorBy = (field: string): string | null => {
        if (this.error.type === 'VALIDATION_ERROR_CODE') {
            return this.error.errors.find((e) => e.field === field)?.message || null;
        }

        return null;
    };
}

export default GQLError;

import { variables } from '@indriver/yrel';
import styled from 'styled-components';

import { Text16Medium, Text18Bold } from '@/shared/typography/text';

export const Title = styled.div`
  ${Text18Bold};

  margin-bottom: ${variables['spacers-m']};
`;

export const InfoBlock = styled.div`
  color: ${variables['text-and-icon-primary']};
`;

export const Description = styled.div`
    ${Text16Medium};

    margin: 24px 0;
`;

export const List = styled.div`
  ${Text16Medium};

  ol {
    list-style: decimal;
    margin-bottom: 12px;

  }

  ul {
    list-style: disc;
  }

  margin-bottom: 11px;
`;

export const Text = styled.div`
  text-decoration: underline;
`;

export const AlertText = styled.div`
  ${Text16Medium};

  color: ${variables['extensions-text-and-icon-onbrand']};
  background-color: ${variables['extensions-background-lightwarning']};
  padding: 12px 16px;
  margin: 24px 0;
  border-radius: 16px;
`;

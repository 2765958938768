import { ThemeProvider } from '@indriver/nova';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import DevPanel from './features/dev-panel';
import initializeI18N from './i18n';
import Resources from './resources';
import AppResources from './services/app-resources/context';
import AuthorizationService from './services/authorization-service';
import ConfigurationService from './services/configuration-service';
import IPCService from './services/ipc-service';
import LogsService from './services/logs-service';
import AppLayout from './shared/ui/layout/app-layout';
import AppRouter from './singlepage/router';

import '@indriver/yrel/dist/fonts/noto.css';
import '@indriver/yrel/dist/theme-light/theme.css';
import '@indriver/yrel/dist/theme-dark/theme.css';
import './shared/globals.css';

declare global {
    interface Window {
        access: (level: string) => void;
    }
}

class ReactAppRoot {
    private logsService: LogsService;
    private ipcService: IPCService;
    private authorizationService: AuthorizationService;
    private configurationService: ConfigurationService;

    constructor(
        logsService: LogsService,
        ipcService: IPCService,
        authorizationService: AuthorizationService,
        configurationService: ConfigurationService,
    ) {
        this.logsService = logsService;
        this.ipcService = ipcService;
        this.authorizationService = authorizationService;
        this.configurationService = configurationService;
    }

    public initializeI18n = initializeI18N;

    private renderMainRoot = () => {
        return (
            <>
                <DevPanel
                    confugurationService={this.configurationService}
                    authorizationService={this.authorizationService}
                    logsService={this.logsService}
                />

                <AppResources>
                    <ThemeProvider>
                        <BrowserRouter>
                            <AppLayout>
                                <AppRouter
                                    ipcService={this.ipcService}
                                    logsService={this.logsService}
                                    authorizationService={this.authorizationService}
                                    configurationService={this.configurationService}
                                />
                            </AppLayout>
                        </BrowserRouter>
                    </ThemeProvider>
                </AppResources>
            </>
        );
    };

    public render() {
        let appNode = window.document.querySelector<Element>('#app');
        if (appNode === null) {
            this.logsService.write('app does not exist');

            appNode = window.document.createElement('div');
            appNode.id = 'app';
            document.body.appendChild(appNode);
        }

        createRoot(appNode).render(this.renderMainRoot());
    }
}

const main = () => {
    window.access('mainstart');

    const configurationService = new ConfigurationService();

    if (!configurationService.isDev()) {
        Sentry.init({
            dsn: Resources.SENTRY_DSN,
        });
    }

    // @ts-ignore
    window.globalError = function (e: any) {
        console.log('err', e);
        Sentry.captureException(e);
    };

    window.addEventListener('securitypolicyviolation', (event) => {
        const message = `CSP error - ${event.blockedURI}`;
        const error = new Error(message);
        const extra = {
            directive: event.violatedDirective,
            blockedURI: event.blockedURI,
            sourceFile: event.sourceFile,
        };

        Sentry.captureException(error, {
            extra
        });
    });

    const logsService = new LogsService();
    logsService.writeInitialLogLine();

    const ipcService = new IPCService(
        configurationService,
        logsService
    ).listen();
    const authorizationService = new AuthorizationService(
        ipcService,
        logsService
    );

    const app = new ReactAppRoot(
        logsService,
        ipcService,
        authorizationService,
        configurationService
    );

    app.initializeI18n();
    app.render();
};

try {
    main();
} catch (e) {
    let message = 'unknown';
    if (e instanceof Error) {
        message = e.message;
    }

    Sentry.captureMessage(`Main failed with ${message}`);
}

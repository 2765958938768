import { FC, useEffect } from 'react';

import { GQLErrorType } from '@/lib/errors/graphql-error';
import { getSessionErrorMessage } from '@/lib/errors/session';
import YunoService from '@/services/yuno-service';

import YunoCheckoutView from '../checkouts/yuno-checkout';
import { PaymentFormData } from '../index';


const YunoCheckoutController: FC<PaymentFormData> = ({
    paymentInfo,
    services,
    store,
    onHideGoBack
}) => {
    const handlePayment = async (
        yuno: YunoService,
        oneTimeToken: string,
        email?: string
    ): Promise<GQLErrorType> => {
        return services.paymentProviderService.process(async (req) => {
            if (!store.selectedMethod.session?.yuno) {
                return;
            }

            const { data } = await req.topUpWithYuno({
                methodName: store.selectedMethod.session.yuno.methodName,
                vaultedToken: store.selectedMethod.session.yuno.vaultedToken,
                session: store.selectedMethod.session.yuno.sessionToken,
                paymentMethodCode: store.selectedMethod.paymentMethodCode || '',
                amount: parseInt(store.amount, 10),
                email,
                oneTimeToken
            });

            if (data?.topupWithYuno.sdkActionIsRequired) {
                yuno.continuePayment();

                return;
            }

            services.navigatorService.showFinalPage('success');
        });
    };

    useEffect(() => {
        onHideGoBack();

        if (!store.selectedMethod.session?.yuno) {
            services.navigatorService.showFinalPage('error', {
                message: getSessionErrorMessage()
            });


            return;
        }
    }, []);

    return (
        <YunoCheckoutView
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            handlePayment={handlePayment}
            navigatorService={services.navigatorService}
            logsService={services.logsService}
            session={store.selectedMethod.session?.yuno}
            formFields={store.selectedMethod.formFields}
        />
    );
};

export default YunoCheckoutController;

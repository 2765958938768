import { Component, createRef, RefObject } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { currentTargetValue } from '@/lib/forms/selectors';
import { Countries } from '@/lib/locale/countries';
import { AMOUNT_ID } from '@/lib/payments/forms/indentificators';

import * as UI from './ui';

import Resources from '../../../../resources';

enum SymbolPlacement {
    Prefix = 'prefix',
    Postfix = 'postfix',
}

type AmountInputProps = {
    symbol?: string;
    country: Countries;
    error?: string;
    amount: string;
    suggestedAmounts: number[];
    onChange: (value: string) => void;
    onResetAmount: VoidFunction;
    onSuggestedAmountClick: (value: number) => void;
    onSubmitByKeyboard: VoidFunction;
} & WithTranslation;

const DEFAULT_DIGIT = '0';

class AmountInput extends Component<AmountInputProps> {
    private inputRef: RefObject<HTMLInputElement>;
    private symbolPlacement: SymbolPlacement;

    constructor(props: AmountInputProps) {
        super(props);

        this.inputRef = createRef();
        this.symbolPlacement = this.getSymbolPlacement(props.country);
    }

    public componentDidMount = () => {
        if (this.inputRef.current) {
            this.inputRef.current.addEventListener('beforeinput', (e: InputEvent) => {
                if (e.inputType === 'insertLineBreak') {
                    this.props.onSubmitByKeyboard();
                }
            });

            if (this.props.amount === '') {
                this.props.onChange(DEFAULT_DIGIT);
            }
        }

        window?.document.addEventListener('click', this.handleDocumentClick);
    };

    public componentDidUpdate = () => {
        this.unselectSymbol();
    };

    public componentWillUnmount = () => {
        window?.document.removeEventListener('click', this.handleDocumentClick);
    };

    private getSymbolPlacement = (country: Countries) => {
        switch (country) {
            case Countries.Kazakhstan:
                return SymbolPlacement.Postfix;
            default:
                return SymbolPlacement.Prefix;
        }
    };

    private handleDocumentClick = (e: MouseEvent) => {
        if (e.target instanceof HTMLElement) {
            const isChipButton = e.target.dataset.id === Resources.test.chipButton;
            const isAmountInput = e.target.id === AMOUNT_ID;

            if (isChipButton || isAmountInput) {
                return;
            } else {
                this.inputRef?.current?.blur();
            }
        }

        if (this.props.amount === '') {
            this.props.onResetAmount();
        }
    };

    private handleChange = (value: string) => {
        value = value.replace(/\D/g, '');

        while (value.startsWith(DEFAULT_DIGIT)) {
            value = value.substring(1);
        }

        this.props.onChange(value);
    };

    private getNewCaretPosition = (config: {
        caretStart: number,
        caretEnd: number,
        valueLength: number,
        symbolLength: number,
        symbolPlacement: SymbolPlacement
    }) => {
        const {
            caretStart,
            caretEnd,
            symbolLength,
            valueLength,
            symbolPlacement
        } = config;

        const shiftLength = symbolLength === 0 ? 0 : symbolLength + 1;
        let borderPosition: number;

        if (symbolPlacement === SymbolPlacement.Postfix) {
            borderPosition = valueLength - shiftLength;
        } else {
            borderPosition = valueLength;
        }

        if (borderPosition <= 0) {
            return {
                start: 0,
                end: 0
            };
        }

        if (symbolPlacement === SymbolPlacement.Prefix) {
            if (caretStart < shiftLength) {
                return {
                    start: shiftLength,
                    end: shiftLength
                };
            }

            if (caretEnd > borderPosition) {
                return {
                    start: caretStart,
                    end: borderPosition
                };
            }
        } else {
            if (caretStart > borderPosition) {
                return {
                    start: borderPosition,
                    end: borderPosition
                };
            }

            if (caretEnd > borderPosition) {
                return {
                    start: caretStart,
                    end: borderPosition
                };
            }
        }

        return {
            start: caretStart,
            end: caretEnd
        };
    };

    private unselectSymbol = () => {
        if (!this.inputRef.current) {
            return;
        }

        const element = this.inputRef.current;

        const positionStart = element.selectionStart;
        const positionEnd = element.selectionEnd;

        if (positionStart === null || positionEnd === null) {
            return;
        }

        const onlyAmount = element.value.trim().replace(/\D/g, '');
        if (onlyAmount === DEFAULT_DIGIT) {
            if (this.symbolPlacement === SymbolPlacement.Postfix) {
                element.setSelectionRange(1, 1);
            } else {
                const valueLength = element.value.length;
                element.setSelectionRange(valueLength, valueLength);
            }

            return;
        }

        const { start, end } = this.getNewCaretPosition({
            caretStart: element.selectionStart ?? 0,
            caretEnd: element.selectionEnd ?? 0,
            symbolLength: this.props.symbol?.length ?? 0,
            valueLength: element.value.length,
            symbolPlacement: this.symbolPlacement
        });

        element.setSelectionRange(start, end);
    };

    private handleClick = () => {
        this.unselectSymbol();
    };

    private handleFocus = () => {
        this.unselectSymbol();
    };

    private getDisplayValue = () => {
        const { symbol, amount } = this.props;

        return this.symbolPlacement === SymbolPlacement.Postfix ?
            `${amount} ${symbol || ''}`.trim() :
            `${symbol || ''} ${amount}`.trim();
    };

    public render() {
        const { error, suggestedAmounts } = this.props;
        const hasError = Boolean(error);

        return (
            <UI.Wrapper>
                <UI.AmountTitle>
                    { this.props.t('shared.ui.domain.AmountInput.title') }
                </UI.AmountTitle>

                <UI.InputWrapper>
                    <UI.InputContent>
                        <UI.Input
                            ref={this.inputRef}
                            autoFocus
                            $error={hasError}
                            id={AMOUNT_ID}
                            data-id={AMOUNT_ID}
                            inputMode='numeric'
                            placeholder=''
                            pattern='\d*'
                            value={this.getDisplayValue()}
                            onFocus={this.handleFocus}
                            onClick={this.handleClick}
                            onChange={currentTargetValue(this.handleChange)}
                        />
                    </UI.InputContent>

                    {
                        hasError ? (
                            <UI.Error data-id={`${AMOUNT_ID}-validation-error`}>
                                {error}
                            </UI.Error>
                        ) : null
                    }
                </UI.InputWrapper>

                {
                    suggestedAmounts && suggestedAmounts.length > 0 ? (
                        <UI.ChipsWrapper>
                            {
                                suggestedAmounts.map((value, index) => (
                                    <UI.Chip
                                        key={index}
                                        data-id={Resources.test.chipButton}
                                        onClick={() => this.props.onSuggestedAmountClick(value)}
                                    >
                                        {value}
                                    </UI.Chip>
                                ))
                            }
                        </UI.ChipsWrapper>
                    ) : <UI.ChipsEmptySpace $show={!this.props.error} />
                }
            </UI.Wrapper>
        );
    }
}

export default withTranslation()(AmountInput);

import { FC } from 'react';

import { GQLErrorType } from '@/lib/errors/graphql-error';
import { FormValues } from '@/lib/payments/forms';

import { PaymentFormData } from '../index';

import CredoraxBankCardView from './credorax';
import CredoraxSavedCardView from './credorax/saved-card';

const CredoraxBankCardController: FC<PaymentFormData> = ({
    store,
    paymentInfo,
    services,
}) => {
    const handleSavedTopUpClick = () => {
        services.analyticService.event('processing.topup_saved_card_pay.click', undefined);
    };

    const handleSubmit = (formFillValues: FormValues): Promise<GQLErrorType> => {
        return services.topupService.topUp(
            store.amount,
            store.selectedMethod,
            formFillValues,
            paymentInfo.country,
        );
    };

    /*TODO https://indriver.atlassian.net/browse/PRC-2208*/
    return store.selectedMethod.savedInstruments?.bankCard ? (
        <CredoraxSavedCardView
            country={paymentInfo.country}
            panValue={store.selectedMethod.savedInstruments?.bankCard[0].last4}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={store.selectedMethod.formFields}
            onTopUpClick={handleSavedTopUpClick}
            onSubmit={handleSubmit}
        />
    ) : (
        <CredoraxBankCardView
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={store.selectedMethod.formFields}
            onSubmit={handleSubmit}
        />
    );
};

export default CredoraxBankCardController;

import { Close } from '@indriver/mireska';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { InformationScreen } from '@/features/information-screen';
import DisplayedError from '@/lib/errors/displayed-error';
import NavigatorService from '@/services/navigator-service';

type InternalErrorPageProps = {
    navigatorService: NavigatorService;
    error?: DisplayedError;
}

const InternalErrorPage: FC<InternalErrorPageProps> = ({
    navigatorService,
    error,
}) => {
    useEffect(() => {
        window.access('internalerrorpage');
    }, []);

    const { t } = useTranslation();
    const displayedError = error || DisplayedError.parse(navigatorService.getQuery());

    const handleClick = useCallback(() => {
        navigatorService.goHome();
    }, []);

    return (
        <InformationScreen
            type='error'
            heading={displayedError.title}
            description={
                displayedError.messages.map(message => (
                    <div key={message}>
                        {message}
                    </div>
                ))
            }
            errorStatus={displayedError.errorStatus}
            icon={Close}
            buttonText={t('pages.internalError.submitCaption')}
            onClickButton={handleClick}
        />
    );
};

export default InternalErrorPage;

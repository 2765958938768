import styled from 'styled-components';

import { Text16Medium } from '@/shared/typography/text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const ImageContainer = styled.div`
  border-radius: 12px;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  padding: 0;
`;

export const Image = styled.img`
  width: 100%;
  margin-top: -2px;
`;

export const Text = styled.div`
  ${Text16Medium};

  display: flex;
  justify-content: center;
  padding-bottom: 26px;
`;

export const InfoContent = styled.div`
  padding-bottom: 26px;
  box-sizing: border-box;

  span {
    ${Text16Medium};
  }
`;

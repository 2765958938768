import i18n from 'i18next';

import Validation, { Validator } from '../../forms/validation';
import { Countries } from '../../locale/countries';

export type DocumentMeta = {
    title: string;
    mask?: string;
    validators: Validator[];
}

export const getDefaultDocumentMeta = () => ({
    title: i18n.t('services.formBuilder.documents.defaultId'),
    validators: [Validation.required],
});

/**
 * Dlocal documentation
 * @link {https://docs.dlocal.com/reference/country-reference}
 *
 * Mask pattern documentation
 * @link {https://imask.js.org/guide.html#masked-pattern}
 *
 * Payment-provider service adapters for each country
 */
export const getDocumentMeta = (country: Countries): DocumentMeta => {
    switch (country) {
        case Countries.Mexico:
            // example: GAPI930504HGRXNR01
            return {
                title: i18n.t('services.formBuilder.documents.mexicoId'),
                mask: 'aaaa000000aaaaaa00',
                validators: [Validation.required, Validation.stringSizeValidator(10, 18)]
            };
        case Countries.Bolivia:
            // example: 1234567
            return {
                title: i18n.t('services.formBuilder.documents.boliviaId'),
                mask: '0'.repeat(20),
                validators: [Validation.required, Validation.stringSizeValidator(5, 20)]
            };
        case Countries.Colombia:
            // example: 123456789
            return {
                title: i18n.t('services.formBuilder.documents.colombiaId'),
                mask: '0000000000',
                validators: [Validation.required, Validation.stringSizeValidator(6, 10)]
            };
        case Countries.Brazil:
            /**
             * CPF Generator
             * @link {https://www.freetool.dev/cpf-generator-validator}
             */
            // example: 123.456.789-09
            return {
                title: i18n.t('services.formBuilder.documents.brazilId'),
                mask: '000.000.000-00',
                validators: [Validation.required, Validation.stringSizeValidator(11, 14)],
            };
        case Countries.Ecuador:
            // example: 1712345678
            return {
                title: i18n.t('services.formBuilder.documents.ecuadorId'),
                mask: '0000000000',
                validators: [Validation.required, Validation.stringSizeValidator(10, 10)]
            };
        case Countries.Peru:
            // example: 12345678
            return {
                title: i18n.t('services.formBuilder.documents.peruId'),
                mask: '00000000',
                validators: [Validation.required, Validation.stringSizeValidator(8, 8)]
            };
        case Countries.DominicanRepublic:
            // example: 001-1234567-8
            return {
                title: i18n.t('services.formBuilder.documents.dominicanRepublicId'),
                mask: '000-0000000-0',
                validators: [Validation.stringSizeValidator(11, 13)]
            };
        case Countries.Chile:
            // example: CI 12.345.678-9
            // example: RUT 76.123.456-8
            return {
                title: i18n.t('services.formBuilder.documents.chileId'),
                mask: '00.000.000-a',
                validators: [Validation.required, Validation.stringSizeValidator(8, 12)]
            };
        case Countries.Egypt:
            // example: 12345678901234
            return {
                title: i18n.t('services.formBuilder.documents.egyptId'),
                mask: '00000000000000',
                validators: [Validation.required, Validation.stringSizeValidator(14, 14)]
            };
        case Countries.Indonesia:
            // example: 3512115501990001
            return {
                title: i18n.t('services.formBuilder.documents.indonesiaId'),
                mask: '0000000000000000',
                validators: [Validation.required, Validation.stringSizeValidator(16, 16)],
            };

        case Countries.Nicaragua:
            // example: 123-456789-0004C
            return {
                title: i18n.t('services.formBuilder.documents.nicaraguaId'),
                mask: '000-000000-0000a',
                validators: [Validation.required, Validation.stringSizeValidator(11, 16)],
            };
        case Countries.India:
            // example: ABCDE1234F
            return {
                title: i18n.t('services.formBuilder.documents.indiaId'),
                mask: 'aaaaa0000a',
                validators: [Validation.required, Validation.stringSizeValidator(10, 10)],
            };
        case Countries.Morocco:
            // example: A12345
            return {
                title: i18n.t('services.formBuilder.documents.moroccoId'),
                mask: 'a[a]00000000000000000000',
                validators: [Validation.required, Validation.stringSizeValidator(5, 20)],
            };
        case Countries.Malaysia:
            // example: 930101142019
            return {
                title: i18n.t('services.formBuilder.documents.malaysiaId'),
                mask: '000000000000',
                validators: [Validation.required, Validation.stringSizeValidator(12, 12)],
            };
        case Countries.Kenya:
            // example: 12345678
            return {
                title: i18n.t('services.formBuilder.documents.kenyaId'),
                mask: '00000000',
                validators: [Validation.required, Validation.stringSizeValidator(7, 8)],
            };
        case Countries.SouthAfrica:
            // example: 9001011234567
            return {
                title: i18n.t('services.formBuilder.documents.southAfricaId'),
                mask: '0000000000000',
                validators: [Validation.optional([Validation.stringSizeValidator(13, 13)])],
            };
        case Countries.Nigeria:
            // example: 12345678901
            return {
                title: i18n.t('services.formBuilder.documents.nigeriaId'),
                mask: '00000000000',
                validators: [Validation.required, Validation.stringSizeValidator(11, 11)],
            };
        case Countries.CostaRica:
            return {
                title: i18n.t('services.formBuilder.documents.costaricaId'),
                mask: '000000000',
                validators: [Validation.required, Validation.stringSizeValidator(9, 9)],
            };
        case Countries.Thailand:
            return {
                title: i18n.t('services.formBuilder.documents.thailandId'),
                mask: '0 0000 00000 00 0',
                validators: [Validation.required, Validation.stringSizeValidator(17, 17)],
            };
        case Countries.Pakistan:
            return {
                title: i18n.t('services.formBuilder.documents.pakistanId'),
                mask: '00000-0000000-0',
                validators: [Validation.required, Validation.stringSizeValidator(15, 15)],
            };
        case Countries.Panama:
            return {
                title: i18n.t('services.formBuilder.documents.panamaId'),
                mask: '0-000-0000',
                validators: [Validation.required, Validation.stringSizeValidator(8, 10)],
            };
        case Countries.Argentina:
            return {
                title: i18n.t('services.formBuilder.documents.argentinaId'),
                mask: '00000000000',
                validators: [Validation.required, Validation.or([
                    Validation.stringSizeValidator(7, 9),
                    Validation.stringSizeValidator(11, 11),
                ])],
            };
        case Countries.Tanzania:
            return {
                title: i18n.t('services.formBuilder.documents.tanzaniaId'),
                mask: '0'.repeat(20),
                validators: [
                    Validation.required,
                    Validation.stringSizeValidator(20, 20)
                ],
            };
        case Countries.Turkey:
            return {
                title: i18n.t('services.formBuilder.documents.turkeyId'),
                mask: '0'.repeat(11),
                validators: [
                    Validation.required,
                    Validation.stringSizeValidator(11, 11)
                ],
            };
        case Countries.Tunisia:
            return {
                title: i18n.t('services.formBuilder.documents.tunisiaId'),
                mask: '00000000',
                validators: [Validation.required, Validation.stringSizeValidator(8, 8)]
            };
        default:
            return getDefaultDocumentMeta();
    }
};

class Text {
    public static capitalizeFirstLetter = (value: string): string => {
        value = value.toLowerCase();

        return value.charAt(0).toUpperCase() + value.slice(1);
    };

    public static withZero = (t: number | string): string =>
        t.toString().length === 1 ? `0${t}` : t.toString();

    public static replaceAsterisks = (value: string): string =>
        value.replace(/\*/g, '•');

    public static replaceIndriverEmail = (value:string): string =>{
        const pattern = /^\d+@(indriver|indrive)\.com$/;

        return pattern.test(value) ? '' : value;
    };
}

export default Text;

import { variables } from '@indriver/yrel';
import styled from 'styled-components';

import { Text16Medium } from '@/shared/typography/text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const ImageContainer = styled.div`
  margin-bottom: ${variables['spacers-s']};
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  padding: 0 ${variables['spacers-m']};
`;

export const Text = styled.span`
  ${Text16Medium};

  width: 100%;
  text-align: center;
  padding-bottom: 16px;
`;

import { MoreHoriz } from '@indriver/mireska';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from '@/lib/format/text';
import { PaymentMethodViewItem } from '@/services/view-service';
import Modal from '@/shared/ui/core/modal';

import Resources from '../../resources';
import ConfirmModal from '../../shared/ui/domain/confirm-modal';
import PaymentMethodSelect from '../../shared/ui/domain/payment-method-select';

import * as UI from './ui';

type PaymentMethodsListProps = {
    title?: string;
    open: boolean;
    selectedMethodIndex: number | null;
    paymentMethods: PaymentMethodViewItem[];
    onMethodSelect: (index: number) => void;
    onMethodDelete: (index: number) => void;
    onClose: VoidFunction;
}

const PaymentMethodsList: FC<PaymentMethodsListProps> = ({
    open,
    title,
    selectedMethodIndex,
    paymentMethods,
    onMethodSelect,
    onMethodDelete,
    onClose,
}) => {
    const { t } = useTranslation();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [methodIndexToDelete, setMethodIndexToDelete] = useState<number|null>(null);

    const toggleDeleteModal = useCallback(() => setDeleteModalOpen(!deleteModalOpen), [deleteModalOpen]);

    const handleMoreClick = useCallback((index: number) => {
        setMethodIndexToDelete(index);
        toggleDeleteModal();
    }, []);

    const handleRemoveClick = useCallback((index: number|null) => {
        if (index === null || !onMethodDelete) {
            return;
        }

        setDeleteModalOpen(false);
        onMethodDelete(index);
    }, [toggleDeleteModal, onMethodDelete]);

    const methodToDelete = methodIndexToDelete !== null ? paymentMethods[methodIndexToDelete] : undefined;

    return (
        <>
            <Modal
                title={title}
                rootElementId='payment-list-root-element'
                open={open}
                onClose={onClose}
            >
                <UI.BodyWrapper dir={Resources.getDir()}>
                    {
                        paymentMethods.map((method, index) => (
                            <UI.ItemWrapper
                                key={method.id}
                                data-id={Resources.test.paymentMethodItem}
                                data-method-type={method.dataMethodTypeId}
                                data-show={method.show}
                                $selected={selectedMethodIndex === index}
                                $show={method.show}
                            >
                                <PaymentMethodSelect
                                    title={method.name}
                                    description={method.description}
                                    icon={method.icon}
                                    onClick={() => onMethodSelect(index)}
                                >
                                    {
                                        method.isRemovable? (
                                            <div data-id={Resources.test.moreBottomSheetButton}>
                                                <MoreHoriz
                                                    size={24}
                                                    onClick={
                                                        (e) => {
                                                            e.stopPropagation();
                                                            handleMoreClick(index);
                                                        }
                                                    }
                                                />
                                            </div>
                                        ): undefined
                                    }
                                </PaymentMethodSelect>
                            </UI.ItemWrapper>
                        ))
                    }
                </UI.BodyWrapper>
            </Modal>

            {
                methodToDelete ? (
                    <ConfirmModal
                        open={deleteModalOpen}
                        title={Text.replaceAsterisks(methodToDelete.name)}
                        confirmCaption={t('features.PaymentMethodsList.remove')}
                        cancelCaption={t('features.PaymentMethodsList.closeDeleting')}
                        confirmDataId={Resources.test.removeDeleteModalButton}
                        cancelDataId={Resources.test.toggleDeleteModalButton}
                        design={['error-low', 'secondary']}
                        onSubmit={() => handleRemoveClick(methodIndexToDelete)}
                        onCancel={toggleDeleteModal}
                    />
                ) : null
            }
        </>
    );
};

export default PaymentMethodsList;

import { Transfer, Card, Calculate, Money2, Mobile, BankAccount  } from '@indriver/mireska';
import { RoundIcon } from '@indriver/nova';
import { FC } from 'react';

import {
    PaymentType
} from '@/lib/payments/payments';
import Providers from '@/lib/payments/providers';
import { PaymentMethod } from '@/services/payment-info-service';

import * as UI from './ui';

const BRAND_IMAGE_TYPE = 'brand-image';
const UI_ICON_TYPE = 'ui-icon';

type Icon = {
    type: typeof BRAND_IMAGE_TYPE;
    src: string;
} | {
    type: typeof UI_ICON_TYPE;
    element: typeof Transfer;
}

const DEFAULT_ICON: Icon = {
    type: UI_ICON_TYPE,
    element: Transfer,
};

const getProviderImg = (method: PaymentMethod): Icon | null => {
    let brandImg = Providers.getBrandImg(method.methodName);

    if (!brandImg) {
        brandImg = Providers.getBrandImg(method.providerName);
    }

    if (brandImg) {
        return {
            type: BRAND_IMAGE_TYPE,
            src: brandImg,
        };
    }

    return null;
};

const getMethodIconType = (method: PaymentMethod): Icon => {
    const icon = getProviderImg(method);

    if (icon !== null) {
        return icon;
    }

    switch (method.paymentType) {
        case PaymentType.BANK_CARD:
            return {
                type: UI_ICON_TYPE,
                element: Card,
            };
        case PaymentType.BANK_TRANSFER:
            return {
                type: UI_ICON_TYPE,
                element: BankAccount,
            };
        case PaymentType.CASH:
            return {
                type: UI_ICON_TYPE,
                element: Money2,
            };

        case PaymentType.TERMINAL:
            return {
                type: UI_ICON_TYPE,
                element: Calculate,
            };

        case PaymentType.PHONE_BALANCE:
            return {
                type: UI_ICON_TYPE,
                element: Mobile,
            };
        default:
            return DEFAULT_ICON;
    }
};

type PaymentMethodIconProps = {
    method?: PaymentMethod;
}

const PaymentMethodIcon: FC<PaymentMethodIconProps> = ({ method }) => {
    const icon = method ? getMethodIconType(method) : DEFAULT_ICON;

    if (icon.type === BRAND_IMAGE_TYPE) {
        return (
            <RoundIcon content={<UI.Image src={icon.src} />} />
        );
    }

    return (
        <RoundIcon
            design='secondary'
            content={<icon.element size={40} />}
        />
    );
};

export default PaymentMethodIcon;

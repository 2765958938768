import { FC, useEffect, useState } from 'react';

import FormFieldView from '@/features/form-field-view';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import {
    FormValues,
} from '@/lib/payments/forms';
import CenterLoader from '@/shared/ui/layout/center-loader';

import { PaymentFormData } from '../../index';

const PayrailsGenericCheckout: FC<PaymentFormData> = ({
    store,
    services,
    paymentInfo,
    onHideGoBack
}) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        onHideGoBack();
    }, []);

    const handleSubmit = async (formFillValues: FormValues): Promise<GQLErrorType> => {
        setLoading(true);

        return services.topupService.topUp(
            store.amount,
            store.selectedMethod,
            formFillValues,
            paymentInfo.country,
        );
    };

    return (
        <CenterLoader loading={loading}>
            <FormFieldView
                country={paymentInfo.country}
                savedDocuments={paymentInfo.savedDocuments}
                formFields={store.selectedMethod.formFields}
                onSubmit={handleSubmit}
            />
        </CenterLoader>
    );
};

export default PayrailsGenericCheckout;

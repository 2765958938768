import { FC } from 'react';

import * as UI from './ui';

type RoundedSectionsProps = {
    top: React.ReactNode | React.ReactNode[];
    middle?: React.ReactNode | React.ReactNode[];
    foldBottom?: boolean;
    bottom: React.ReactNode | React.ReactNode[];
}

const RoundedSections: FC<RoundedSectionsProps> = ({ top, middle, foldBottom, bottom }) => {
    return (
        <UI.Wrapper>
            <UI.TopSection>
                {top}
            </UI.TopSection>

            {
                middle ? (
                    <UI.MiddleSection>
                        {middle}
                    </UI.MiddleSection>
                ) : null
            }

            <UI.BottomSection $fold={foldBottom}>
                {bottom}
            </UI.BottomSection>
        </UI.Wrapper>
    );
};

export default RoundedSections;

import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FillForm from '@/features/payment-fill-form';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { Countries } from '@/lib/locale/countries';
import { formatLast4toDots } from '@/lib/payments/credit-card/format';
import {
    FormSection,
    FormValues,
} from '@/lib/payments/forms';
import FormInput from '@/shared/ui/core/form-input';
import SubmitForm from '@/shared/ui/domain/submit-form';

import Resources from '../../../../../../../resources';
import { AppData } from '../../../../../index';

type CredoraxSavedCardProps = {
    country: Countries;
    panValue: string;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    formFields: FormSection[];
    onTopUpClick: VoidFunction;
    onSubmit: (formFillValues: FormValues) => Promise<GQLErrorType>;
}

const CredoraxSavedCardView: FC<CredoraxSavedCardProps> = ({
    country,
    panValue,
    savedDocuments,
    formFields,
    onTopUpClick,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const paymentFillFormRef = useRef<FillForm>(null);

    const handleSubmit = useCallback(async () => {
        onTopUpClick();

        let data: Record<string, string> = {};

        if (paymentFillFormRef.current) {
            if (!paymentFillFormRef.current.validate()) {
                return;
            }

            const paymentFillFormData = paymentFillFormRef.current.getData();

            data = {
                ...paymentFillFormData,
            };
        }

        setLoading(true);
        const error = await onSubmit(data);
        if (error) {
            if (paymentFillFormRef.current) {
                paymentFillFormRef.current.setError(error);
            }
        }

        setLoading(false);
    }, [paymentFillFormRef, onTopUpClick]);

    return (
        <SubmitForm
            loading={loading}
            onSubmit={handleSubmit}
        >
            <FormInput
                disabled
                title={t('shared.ui.domain.CreditCardOutline.panCaption')}
                data-id={Resources.test.existedCard}
                value={formatLast4toDots(panValue)}
            />

            {
                formFields.length > 0 ? (
                    <FillForm
                        ref={paymentFillFormRef}
                        country={country}
                        savedDocuments={savedDocuments}
                        fields={formFields}
                    />
                ) : <></>
            }
        </SubmitForm>
    );
};

export default CredoraxSavedCardView;

import React, { ChangeEvent, RefObject } from 'react';

import { ValidationError } from '@/lib/forms/validation';
import { CardType } from '@/lib/payments/credit-card';
import { getCardType } from '@/lib/payments/credit-card/cardtypes';

import { formatCVC, isCVCFilled } from '../lib/format';
import { validateCVV } from '../lib/validate';
import { Input } from '../ui';

import * as UI from './ui';

type CVVProps = {
    ref: RefObject<CVV>;
    cardNumber: string;
    onError: (error: ValidationError) => void;
    onChange: (cardNumber: string) => void;
    onCompleted: VoidFunction;
}

class CVV extends React.Component<CVVProps> {
    private inputRef: RefObject<HTMLInputElement>;
    constructor(props: CVVProps) {
        super(props);

        this.inputRef = React.createRef();
    }

    private handleValueChange = (evt: ChangeEvent<HTMLInputElement>) => {
        if (!this.props.cardNumber) {
            return;
        }

        const isAmex = getCardType(this.props.cardNumber) === CardType.AMEX;
        const value = formatCVC(evt.target.value, isAmex);
        this.props.onChange(value);
        this.props.onError(undefined);

        if (isCVCFilled(evt.target.value, isAmex)) {
            const error = validateCVV(evt.target.value, isAmex);

            if (error) {
                this.props.onError(error);

                return;
            }

            this.props.onCompleted();
        }
    };

    private handleFocus = () => {
        if (this.inputRef.current) {
            this.inputRef.current.type = '';
        }
    };

    private handleBlur = (evt: ChangeEvent<HTMLInputElement>) => {
        if (this.inputRef.current) {
            this.inputRef.current.type = 'password';
        }

        this.validate(evt.target.value);
    };

    public validate = (value = this.inputRef.current?.value || ''): boolean => {
        const isAmex = getCardType(this.props.cardNumber) === CardType.AMEX;
        const err = validateCVV(value, isAmex);
        this.props.onError(err);

        return !err;
    };

    public focus = (): void => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };

    public render() {
        return (
            <UI.Wrapper>
                <Input
                    ref={this.inputRef}
                    autoComplete='cc-csc'
                    pattern='\d*'
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    onChange={this.handleValueChange}
                />
            </UI.Wrapper>
        );
    }
}

export default CVV;

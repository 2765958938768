import { gql } from '@apollo/client';

export type TopupWithNuveiSavedCardMutation = {
    input: TopupWithNuveiSavedCardInput;
}

export type TopupWithNuveiSavedCardInput = {
    amount: number;
    email: string;
    paymentInstrumentID: string;
}

export default gql(`
    mutation topupWithNuveiSavedCard ($input: TopupWithNuveiSavedCardInput!){
        topupWithNuveiSavedCard(
            input: $input
        ) {
            nextAction
        }
    }
`);

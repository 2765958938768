import * as MIcons from '@indriver/mireska';
import { Button, DesignRoundIcon, RoundIcon } from '@indriver/nova';
import { variables } from '@indriver/yrel';

import * as UI from './ui';

type InformationScreenType = 'error' | 'in-progress' | 'neutral' | 'warning';

type InformationScreenProps = {
    icon: (typeof MIcons)[keyof typeof MIcons];
    type: InformationScreenType
    heading: string;
    description?: string | React.ReactNode;
    errorStatus?: string;
    buttonText: string;
    onClickButton?: VoidFunction;
};

export const InformationScreen = ({
    icon,
    type,
    heading,
    description,
    errorStatus,
    buttonText,
    onClickButton,
}: InformationScreenProps) => {
    const IconContent = icon;
    const designStyles: Record<InformationScreenType, DesignRoundIcon> = {
        error: 'error',
        warning: 'sand',
        ['in-progress']: 'neutral',
        neutral: 'neutral',
    };

    return (
        <UI.Wrapper>
            <UI.ContentWrapper>
                <RoundIcon
                    size='xxxl'
                    design={designStyles[type]}
                    style={{ marginBottom: variables['spacers-xl'] }}
                    content={<IconContent size={40} />}
                />

                {heading ? <UI.Title data-id='title'>{heading}</UI.Title> : null}

                {
                    description ? (
                        <UI.TextWrapper>
                            <UI.Description
                                data-id='description'
                            >
                                {description}
                            </UI.Description>
                        </UI.TextWrapper>
                    ): null
                }

                {
                    errorStatus ? (
                        <UI.TextWrapper>
                            <UI.ErrorStatusDescription
                                data-id='errorStatus'
                            >
                                {errorStatus}
                            </UI.ErrorStatusDescription>
                        </UI.TextWrapper>
                    ) : null
                }
            </UI.ContentWrapper>

            <UI.BottomWrapper>
                {
                    onClickButton ? (
                        <Button
                            data-id='submit'
                            size='l'
                            style={{ width: '100%' }}
                            onClick={onClickButton}
                        >
                            {buttonText}
                        </Button>
                    ): null
                }
            </UI.BottomWrapper>
        </UI.Wrapper>
    );
};
